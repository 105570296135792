import React, { FunctionComponent, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button as MuiButton, IconButton as MuiIconButton, Tooltip as MuiTooltip, Menu as MuiMenu, MenuItem as MuiMenuItem, Divider as MuiDivider, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { ProgressSpinner } from '@dxlm/components';
import { IMenuItem } from '@dxlm/interfaces';

interface IButtonProps {
    icon?: JSX.Element;
    iconPosition?: 'start' | 'end'
    iconOnly?: boolean;
    loading?: boolean;
    disabled?: boolean;
    href?: string;
    hrefReplace?: boolean;
    className?: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'light' | 'lightGrey';
    autoFocus?: boolean;
    ref?: React.ForwardedRef<HTMLButtonElement>;
    isFileUpload?: boolean;
    allowedFileTypes?: string;

    menuButtonOptions?: IMenuItem[];
    menuButtonOptionClicked?: (option: IMenuItem) => void;

    type?: 'button' | 'submit' | 'reset';

    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;

    /**
     * Return true to clear the input, otherwise false
     */
    onFileUploaded?: (file: File) => void;

    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';

    tooltip?: string;
    tooltipPlacement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';

    children?: any;

    style?: any;
}
const Button: FunctionComponent<IButtonProps> = React.forwardRef((props: IButtonProps, ref) => {

    const [buttonMenuAnchorEl, setButtonMenuAnchorEl] = useState<HTMLElement>(null);
    const buttonMenuOpen = Boolean(buttonMenuAnchorEl);

    const handleMenuItemClicked = (option: IMenuItem) => {
        setButtonMenuAnchorEl(null);
        if (props.menuButtonOptionClicked) {
            props.menuButtonOptionClicked(option);
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.menuButtonOptions?.length > 0) {
            setButtonMenuAnchorEl(e.currentTarget as HTMLElement);
        } else if (!props.isFileUpload && props.onClick) {
            props.onClick(e);
        } else if (props.isFileUpload) {
            inputRef.current.click();
        }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onFileUploaded && e.target.files.length > 0) {
            props.onFileUploaded(e.target.files[0]);
        }

        inputRef.current.value = null;
    }

    const icon: JSX.Element | undefined = props.loading
        ? (<ProgressSpinner size={20} />)
        : props.icon;

    const inputRef = useRef<HTMLInputElement>();
    const fileUploadInput = !props.isFileUpload ? null : (
        <input type='file' ref={inputRef} hidden accept={props.allowedFileTypes} onChange={handleFileChange} />
    );

    const button = props.iconOnly && props.icon ? (
        <MuiIconButton
            disabled={props.disabled}
            type={props.type ?? 'button'}
            size={props.size ?? 'medium'}
            onClick={handleClick}
            className={props.className}
            color={props.color}
            autoFocus={props.autoFocus}
            ref={ref}
            style={props.style}
        >
            {icon}
            {fileUploadInput}
        </MuiIconButton>
    ) : (
        <MuiButton
            startIcon={props.iconPosition !== 'end' ? icon : null}
            endIcon={props.iconPosition === 'end' && icon ? icon : props.menuButtonOptions?.length > 0 ? <KeyboardArrowDown />  : null}
            disabled={props.loading || props.disabled}
            type={props.type ?? 'button'}
            variant={props.variant ?? 'contained'}
            size={props.size ?? 'medium'}
            onClick={handleClick}
            className={props.className}
            color={props.color}
            autoFocus={props.autoFocus}
            ref={ref}
            style={props.style}
        >
            {props.children}
            {fileUploadInput}
        </MuiButton>
    );

    const wrappedButton = props.tooltip ? (
        <MuiTooltip title={props.tooltip} placement={props.tooltipPlacement}>
            {button}
        </MuiTooltip>
    ) : button;

    if (props.href && !props.disabled) {
        return (
            <Link to={props.href} replace={props.hrefReplace}>
                {wrappedButton}
            </Link>
        )
    }

    return (
        <>
            {wrappedButton}
            {
                props.menuButtonOptions?.length > 0 && (
                    <MuiMenu
                        open={buttonMenuOpen}
                        anchorEl={buttonMenuAnchorEl}
                        onClose={() => setButtonMenuAnchorEl(null)}
                    >
                        {
                            props.menuButtonOptions.map((x, index) => x.isDivider ? (
                                <MuiDivider key={index} />
                            ) : (
                                <MuiMenuItem key={index} onClick={() => handleMenuItemClicked(x)}>
                                    {
                                        x.icon && (
                                            <ListItemIcon>
                                                {x.icon}
                                            </ListItemIcon>
                                        )
                                    }
                                    <ListItemText disableTypography>
                                        <Typography variant='body2'>{x.text}</Typography>
                                    </ListItemText>
                                </MuiMenuItem>
                            ))
                        }
                    </MuiMenu>
                )
            }
        </>
    );
});

export default Button;