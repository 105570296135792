import React, { FunctionComponent, useState, useEffect, useMemo, useRef } from 'react';

import { TableCell, TableSortLabel, Popover, Typography, Stack } from '@mui/material';

import { FilterAlt, FilterAltOff, Clear } from '@mui/icons-material';

import { Button, DatePicker, Input, Select } from '@dxlm/components';
import { IMenuItem, TSortDirection } from '@dxlm/interfaces';
import { DEFAULT_DATE_FORMAT } from '../date-picker';
import dayjs from 'dayjs';

type TFilterType = 'text' | 'number' | 'date' | 'select';
export interface ITableColumn {
    title: string;
    dataKey?: string;
    sortable?: boolean;
    filterable?: boolean;
    filterType?: TFilterType;
    filterOptions?: IMenuItem[];
    element?: (dataRow: any) => JSX.Element | string;
    disableRowClick?: boolean;
    allowWrapping?: boolean;
    visible?: boolean;
}

interface ITableHeaderColumnProps {
    columnDef: ITableColumn;
    sortKey?: string;
    sortDirection?: TSortDirection;
    filterValue?: string;
    filterChanged?: (value: any) => void;
    sortingClicked?: () => void;
    disabled?: boolean;
}
const TableHeaderColumn: FunctionComponent<ITableHeaderColumnProps> = (props: ITableHeaderColumnProps) => {

    const [filterValue, setFilterValue] = useState(props.filterValue ?? '');
    const filterValuePreview = useMemo(() => {
        if (filterValue.length > 10) {
            return `${filterValue.substring(0, 7)}...`;
        }
        return filterValue;
    }, [filterValue]);
    const [tempFilterValue, setTempFilterValue] = useState(props.filterValue ?? '');

    const filterButtonRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);

    const updateFilterValue = (val: string) => {
        setFilterValue(val);
        if (props.filterChanged) {
            let valToEmit: any = val;
            if (props.columnDef.filterType === 'date') {
                valToEmit = val ? dayjs(val, DEFAULT_DATE_FORMAT) : null;
            } else if (props.columnDef.filterType === 'number') {
                valToEmit = val && !isNaN(parseInt(val)) ? parseInt(val) : null;
            }
            props.filterChanged(valToEmit);
        }
    };

    const sortableHeaderColumn = (title: string) => (
        <TableSortLabel
            active={props.sortKey === props.columnDef.dataKey}
            direction={props.sortDirection === 'ASC' ? 'asc' : 'desc'}
            onClick={props.disabled ? null : props.sortingClicked}
        >
            {title}
        </TableSortLabel>
    );

    const filterBlock = () => (
        <>
            <Button
                iconOnly={!filterValue}
                icon={filterValue ? <FilterAlt fontSize='small' /> : <FilterAltOff fontSize='small' />}
                size='small'
                color={filterValue ? 'primary' : 'lightGrey'}
                onClick={(e) => setFilterOpen(true)}
                variant='text'
                disabled={props.disabled}
            >
                {
                    filterValue && (
                        <Typography variant='caption'>"{filterValuePreview}"</Typography>
                    )
                }
            </Button>
            <Popover
                open={filterOpen}
                anchorEl={filterButtonRef.current}
                onClose={() => setFilterOpen(false)}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <Stack rowGap='15px' style={{padding: '5px 20px', minWidth: '300px'}}>
                    {
                        props.columnDef.filterType === 'select' && props.columnDef.filterOptions?.length > 0 ? (
                            <Select
                                label={`Filter by ${props.columnDef.title}`}
                                options={props.columnDef.filterOptions}
                                value={tempFilterValue}
                                onChange={(e) => setTempFilterValue(e.target.value)}
                            />
                        ) : props.columnDef.filterType === 'date' ? (
                            <DatePicker
                                label={`Filter by ${props.columnDef.title}`}
                                onChange={(e) => setTempFilterValue(e.format(DEFAULT_DATE_FORMAT))}
                                value={tempFilterValue ? dayjs(tempFilterValue, DEFAULT_DATE_FORMAT) : null}
                            />
                        ) : (
                            <Input
                                label={`Filter by ${props.columnDef.title}`}
                                value={tempFilterValue}
                                onChange={(e) => setTempFilterValue(e.target.value)}
                                type={props.columnDef.filterType === 'number' ? 'number' : 'text'}
                                endIcon={<Clear />}
                                endIconIsButton={true}
                                endIconButtonClicked={() => {
                                    setTempFilterValue('');
                                    updateFilterValue('');
                                }}
                                autoFocus={true}
                            />
                        )
                    }
                    <Stack direction='row' justifyContent='space-between'>
                        <Button
                            size='small'
                            variant='text'
                            color='error'
                            disabled={!tempFilterValue}
                            onClick={() => {
                                setTempFilterValue('');
                                updateFilterValue('');
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            size='small'
                            variant='text'
                            disabled={tempFilterValue === filterValue}
                            onClick={() => updateFilterValue(tempFilterValue)}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );

    useEffect(() => {
        setFilterOpen(false);
        setTempFilterValue(filterValue);
    }, [filterValue]);

    return (
        <TableCell ref={filterButtonRef}>
            {props.columnDef.sortable ? sortableHeaderColumn(props.columnDef.title) : props.columnDef.title}
            {props.columnDef.filterable && filterBlock()}
        </TableCell>
    )
};

export default TableHeaderColumn;