import React, { FunctionComponent } from 'react';

import { FormControl, InputLabel, Select as MuiSelect, MenuItem, SelectChangeEvent, InputAdornment, Checkbox, Stack, Box } from '@mui/material';
import { Check } from '@mui/icons-material';

import { IMenuItem } from '@dxlm/interfaces';
import StatusChip from '../status-chip/StatusChip';

interface ISelectProps {
    value: any;
    label: string;
    onChange: (event: SelectChangeEvent<string>) => void;
    options: IMenuItem[];
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    name?: string;
    startIcon?: JSX.Element;
    size?: 'small' | 'medium';
    margin?: 'dense' | 'normal' | 'none';
    multiple?: boolean;
}
const Select: FunctionComponent<ISelectProps> = (props: ISelectProps) => {

    const uniqueName = props.name ?? props.label;

    const getIcon = (icon: JSX.Element) => {
        const color = props.error ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !props.startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(props.startIcon)}
        </InputAdornment>
    );

    return (
        <FormControl fullWidth error={props.error} required={props.required} margin={props.margin}>
            <InputLabel
                id={`select-${uniqueName}`}
                size={props.size === 'small' ? 'small' : 'normal'}
            >
                {props.label}
            </InputLabel>
            <MuiSelect
                labelId={`select-${uniqueName}`}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                name={uniqueName}
                startAdornment={buildStartIcon()}
                size={props.size ?? 'medium'}
                readOnly={props.readOnly}
                multiple={props.multiple ?? false}
                renderValue={(selected: any) => {
                    if (!props.multiple) {
                        const matchingItem = props.options.find(x => (x.value ?? x.text) === selected)
                        return matchingItem?.text ?? matchingItem?.content;
                    }

                    return (
                        <Stack flexWrap='wrap' gap={0.5} flexDirection='row'>
                            {
                                props.options.filter(x => selected?.indexOf(x.value ?? x.text) > -1).map(x => (
                                    <StatusChip
                                        key={x.value ?? x.text}
                                        size='small'
                                        status={(x.value ?? x.text) as any}
                                        textOverride={x.text ?? x.value}
                                    />
                                ))
                            }
                        </Stack>
                    )
                }}
            >
                {
                    props.options.filter(x => !x.hidden).map((option, index) => (
                        <MenuItem
                            value={option.value ?? option.text}
                            key={option.value ?? option.text ?? index}
                        >
                            <Stack direction='row' justifyContent='space-between' flex={1}>
                                {option.content ?? option.text}
                                {
                                    props.multiple && props.value?.indexOf(option.value ?? option.text) > -1 && (
                                        <Check color='primary' />
                                    )
                                }
                            </Stack>
                        </MenuItem>
                    ))
                }
            </MuiSelect>
        </FormControl>
    )
};

export default Select;