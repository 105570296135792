import React, { FunctionComponent, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { useScreenSize } from '@dxlm/hooks';
import { Dayjs } from 'dayjs';

export const DEFAULT_DATE_FORMAT = 'D MMM YYYY';

interface IDatePickerProps {
    label: string;
    value: Dayjs;
    onChange: (newDate: Dayjs) => void;
    name?: string;
    disabled?: boolean;
    readOnly?: boolean;
    error?: boolean;
    required?: boolean;
    dateFormat?: string;

    minDate?: Dayjs;
    maxDate?: Dayjs;
}
const DatePicker: FunctionComponent<IDatePickerProps> = (props: IDatePickerProps) => {
    const { isMobile } = useScreenSize();
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const dateFormat = props.dateFormat ?? DEFAULT_DATE_FORMAT;

    const getTextField = (params: any) => {

        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            setIsPickerOpen(!isPickerOpen);
        }

        return (
            <TextField {...params}
                name={props.name}
                disabled={props.disabled}
                error={props.error}
                required={props.required}
                onClick={handleClick}
                inputProps={{
                    readOnly: true
                }}
                value={props.value ? props.value.format(dateFormat) : ''}
            />
        )
    }

    const getDatePicker = () => {
        if (isMobile) {
            return (
                <MobileDatePicker
                    onChange={props.onChange}
                    value={props.value}
                    inputFormat={dateFormat}
                    ignoreInvalidInputs={true}
                    label={props.label}
                    disabled={props.disabled}
                    renderInput={getTextField}
                    open={isPickerOpen}
                    onClose={() => setIsPickerOpen(false)}
                    disableMaskedInput={true}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    readOnly={props.readOnly}
                />
            )
        } else {
            return (
                <DesktopDatePicker
                    onChange={props.onChange}
                    value={props.value}
                    inputFormat={dateFormat}
                    label={props.label}
                    renderInput={getTextField}
                    open={isPickerOpen}
                    closeOnSelect={true}
                    onClose={() => setIsPickerOpen(false)}
                    disableMaskedInput={true}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    readOnly={props.readOnly}
                />
            )
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {getDatePicker()}
        </LocalizationProvider>
    )
};

export default DatePicker;