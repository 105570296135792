import React, { FunctionComponent, useRef } from 'react';

import { Unstable_Grid2 as Grid } from '@mui/material';

import { IRateCardAAP, IRateCardProduct, IRateCardProductSize, IRateCardProductSizeRate, IRateCardZone } from '@dxlm/interfaces';

import RateCardProductHeader from './product-header';
import RateCardProductSizeRate from './product-size-rate';
import { TRateCardType } from '.';

interface IRateCardProductRateProps {
    product: IRateCardProduct;
    aaps: IRateCardAAP[];
    zones: IRateCardZone[];
    rateChanged: (productSize: IRateCardProductSize, rate: IRateCardProductSizeRate, newRate: number) => void;
    disabled: boolean;
    mode: TRateCardType;
}
const COLUMN_WIDTH = 150;
const RateCardProductRate: FunctionComponent<IRateCardProductRateProps> = (props: IRateCardProductRateProps) => {
    const scrollingElements = useRef<Array<{ name: string, el: HTMLDivElement }>>([]);

    const handleScrolling = (scrollLeft: number) => {
        for (const scrollingEl of scrollingElements.current) {
            scrollingEl.el.scrollLeft = scrollLeft;
        }
    };
    const storeScrollingElement = (name: string, scrollingElement: HTMLDivElement) => {
        const elementsClone = scrollingElements.current.slice();
        const existingIndex = scrollingElements.current.findIndex(x => x.name === name);
        if (existingIndex >= 0) {
            elementsClone.splice(existingIndex, 1, { name, el: scrollingElement });
        } else {
            elementsClone.push({ name, el: scrollingElement });
        }
        scrollingElements.current = elementsClone;
    };

    return (
        <Grid container>
            <RateCardProductHeader
                product={props.product}
                aaps={props.aaps}
                columnWidth={COLUMN_WIDTH}
                onScroll={handleScrolling}
                scrollHeaderLoaded={(el) => storeScrollingElement('product-header', el)}
                mode={props.mode}
            />
            {
                props.product.sizes.map(ps => (
                    <RateCardProductSizeRate
                        productSize={ps}
                        zones={props.zones}
                        columnWidth={COLUMN_WIDTH}
                        onScroll={handleScrolling}
                        scrollHeaderLoaded={(el) => storeScrollingElement(`ps-header-${ps.id}`, el)}
                        scrollBodyLoaded={(el) => storeScrollingElement(`ps-body-${ps.id}`, el)}
                        rateChanged={(rate, newValue) => props.rateChanged(ps, rate, newValue)}
                        key={ps.id}
                        disabled={props.disabled}
                        mode={props.mode}
                    />
                ))
            }
        </Grid>
    )
};

export default RateCardProductRate;

