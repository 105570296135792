import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { ICreateOrganizationRequest, IDefaultResponseMessage, IOrganization, IOrganizationStandardMailSettings, IStandardMailRate, IUpdateOrganizationRequest, TStatus } from '@dxlm/interfaces';
import { HttpClientError, IQuerySettings, getQuerySettings, QueryKeys } from '@dxlm/models';
import { useHttpClient, useSnackbar } from '@dxlm/hooks';

interface IRequest<T> {
  organizationId: string;
  data: T;
}

export enum OrganizationQueryKeys {
  StandardMailRates = 'StandardMailRates',
  StandardMailSettings = 'StandardMailSettings',
  Self = 'Self'
}

export const useGetOrganization = (organizationId: string, settings?: IQuerySettings) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IOrganization, HttpClientError>([QueryKeys.Organizations, organizationId], () => {
        return GET<IOrganization>(`/v1/organizations/${organizationId}`);
    }, {
      enabled: Boolean(organizationId) && querySettings.enabled
    });
}

export const useGetCurrentOrganization = (settings?: IQuerySettings<IOrganization>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IOrganization, HttpClientError>([QueryKeys.Organizations, OrganizationQueryKeys.Self], () => {
        return GET<IOrganization>(`/v1/organizations/self`, null, {
          autoShowErrorMessages: querySettings.autoShowErrorMessages,
          autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
        });
    }, {
      enabled: querySettings.enabled,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError,
      staleTime: querySettings.staleTime
    });
}

export const useGetOrganizationGlobalStandardMailRates = (settings?: IQuerySettings) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IStandardMailRate[], HttpClientError>([QueryKeys.Organizations, OrganizationQueryKeys.StandardMailRates], () => {
      return GET<IStandardMailRate[]>(`/v1/standard-mail-rates`);
  }, {
    enabled: querySettings.enabled
  });
}

export const useGetOrganizationStandardMailSettings = (organizationId: string, settings?: IQuerySettings) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IOrganizationStandardMailSettings, HttpClientError>([QueryKeys.Organizations, OrganizationQueryKeys.StandardMailSettings, organizationId], () => {
      return GET<IOrganizationStandardMailSettings>(`/v1/organizations/${organizationId}/standard-mail-settings`);
  }, {
    enabled: Boolean(organizationId) && querySettings.enabled
  });
}

export const useCreateOrganization = (navigateAfterCreate?: (organizationId: string) => string) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createOrganization = (request: ICreateOrganizationRequest) => {
    return POST<IOrganization>(`/v1/organizations`, request);
  };

  return useMutation<IOrganization, HttpClientError, ICreateOrganizationRequest>(createOrganization, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Organizations, response.id], response);
      showSnackbar('Organization successfully created');

      if (navigateAfterCreate) {
        const navigateUrl = navigateAfterCreate(response.id);
        navigate(navigateUrl, { replace: true });
      }
    }
  });
}

export const useUpdateOrganization = () => {
  const { PUT } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateOrganization = ({ organizationId, data }: IRequest<IUpdateOrganizationRequest>) => {
    return PUT<IOrganization>(`/v1/organizations/${organizationId}`, data);
  };

  return useMutation<IOrganization, HttpClientError, IRequest<IUpdateOrganizationRequest>>(updateOrganization, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Organizations, response.id], response);
      showSnackbar('Organization successfully updated');
    }
  });
}

export const useDeleteOrganization = (navigateAfterDelete?: (organizationId?: string) => string) => {
  const { DELETE } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteOrganization = (organizationId: string) => {
    return DELETE<IDefaultResponseMessage>(`/v1/organizations/${organizationId}`);
  };

  return useMutation<IDefaultResponseMessage, HttpClientError, string>(deleteOrganization, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([QueryKeys.Organizations, input]);
      showSnackbar('Organization successfully deleted');

      if (navigateAfterDelete) {
        const navigateUrl = navigateAfterDelete(input);
        navigate(navigateUrl, { replace: true });
      }
    }
  });
}

export const useUpdateOrganizationStatus = () => {
  const { PUT, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateOrganizationStatus = ({ organizationId, data }: IRequest<TStatus>) => {
    return PUT<IOrganization>(buildUrl(`/v1/organizations/${organizationId}/status`, { status: data }));
  };

  return useMutation<IOrganization, HttpClientError, IRequest<TStatus>>(updateOrganizationStatus, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Organizations, response.id], response);
      showSnackbar(`Organization successfully ${response.status === 'Disabled' ? 'disabled' : 'restored'}`);
    }
  });
}

export const useSetOrganizationGlobalStandardMailRates = () => {
  const { PUT } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const setOrganizationGlobalStandardMailRates = ({ data }: IRequest<IStandardMailRate[]>) => {
    return PUT<IStandardMailRate[]>(`/v1/standard-mail-rates`, data);
  };

  return useMutation<IStandardMailRate[], HttpClientError, IRequest<IStandardMailRate[]>>(setOrganizationGlobalStandardMailRates, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries([QueryKeys.Organizations, input.organizationId]);
      queryClient.invalidateQueries([QueryKeys.Organizations, OrganizationQueryKeys.StandardMailRates]);
      showSnackbar('Standard Mail Rates saved successfully.');
    }
  });
}

export const useSetOrganizationStandardMailSettings = () => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const setOrganizationStandardMailSettings = ({ organizationId, data }: IRequest<IOrganizationStandardMailSettings>) => {
    return POST<IOrganizationStandardMailSettings>(`/v1/organizations/${organizationId}/standard-mail-settings`, data);
  };

  return useMutation<IOrganizationStandardMailSettings, HttpClientError, IRequest<IOrganizationStandardMailSettings>>(setOrganizationStandardMailSettings, {
    onSuccess: (response, input) => {
      queryClient.setQueryData([QueryKeys.Organizations, OrganizationQueryKeys.StandardMailSettings, input.organizationId], response);
      showSnackbar('Standard mail settings saved');
    }
  });
}