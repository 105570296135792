import React, { FunctionComponent, useState, useEffect } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { Button, Form, FormInputsOutlet } from '@dxlm/components';
import { IFormInput, IFormSubmitResult } from '@dxlm/components/form';
import { AttachMoney, Percent } from '@mui/icons-material';

interface IRateCalculatorForm {
    originalRate: number;
    calculatedRate: number;
    discountPercentage: number;
}

interface IRateCalculatorProps {
    baseRate: number;
    discountPercentage: number;
    open: boolean;
    onClose: () => void;
    onRateSelected: (newCalculatedRate: number, newDiscountPercentage: number) => void;
}
const RateCalculator: FunctionComponent<IRateCalculatorProps> = (props: IRateCalculatorProps) => {

    const [formData, setFormData] = useState<IRateCalculatorForm>({
        originalRate: 0,
        discountPercentage: 0,
        calculatedRate: 0
    });

    // Round to 6 decimal places
    const roundRate = (amount: number) => Math.ceil(amount * 1000000) / 1000000;

    const handleSubmit = (form: IFormSubmitResult) => {
        if (!form.valid) {
            return;
        }

        const submittedData = form.getData<IRateCalculatorForm>();
        props.onRateSelected(submittedData.calculatedRate, submittedData.discountPercentage);
    };

    const handleInputChanged = (val: number, inputName: string) => {
        if (inputName === 'calculatedRate') {
            const rateDiff = props.baseRate - val;
            const newDiscountPercentage = roundRate(rateDiff / props.baseRate * 100);
            setFormData(fd => ({...fd,
                discountPercentage: newDiscountPercentage,
                calculatedRate: val
            }));
        } else if (inputName === 'discountPercentage') {
            const newCalculatedRate = roundRate(props.baseRate - (props.baseRate * (val / 100)));
            setFormData(fd => ({...fd,
                calculatedRate: newCalculatedRate,
                discountPercentage: val
            }));
        }
    };

    useEffect(() => {
        let calculatedRate = props.baseRate ?? 0;
        if (props.discountPercentage > 0) {
            calculatedRate = roundRate(calculatedRate - (calculatedRate * (props.discountPercentage / 100)));
        }
        setFormData(fd => ({...fd,
            originalRate: props.baseRate ?? 0,
            calculatedRate: calculatedRate,
            discountPercentage: props.discountPercentage ?? 0
        }));
    }, [props.baseRate, props.discountPercentage]);

    const formInputs: IFormInput[] = [
        {
            label: 'Original Rate',
            name: 'originalRate',
            type: 'number',
            required: true,
            disabled: true,
            startIcon: <AttachMoney />,
            value: formData.originalRate
        },
        {
            label: 'Calculated Rate',
            name: 'calculatedRate',
            type: 'number',
            required: true,
            min: 0,
            startIcon: <AttachMoney />,
            value: formData.calculatedRate
        },
        {
            label: 'Discount Percentage',
            name: 'discountPercentage',
            type: 'number',
            required: true,
            min: 0,
            max: 100,
            endIcon: <Percent />,
            value: formData.discountPercentage
        }
    ];

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <Form
                inputs={formInputs}
                onSubmit={handleSubmit}
            >
                <DialogTitle>Calculate Rate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Calculate the discounted rate below
                    </DialogContentText>

                    <FormInputsOutlet
                        onInputChange={handleInputChanged}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='error' onClick={props.onClose}>Cancel</Button>
                    <Button
                        variant='text'
                        autoFocus
                        type='submit'
                    >
                        Set Rate
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
};

export default RateCalculator;