import { useState, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


type TScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface IScreenSize {
    currentSize: TScreenSize;
    isMobile: boolean;
    isSmallDesktop: boolean;
}
export const useScreenSize = (): IScreenSize => {
    const [screenSize, setScreenSize] = useState<IScreenSize>({
        currentSize: 'xs',
        isMobile: true,
        isSmallDesktop: false
    });

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));

    useEffect(() => {
        if (isXS) {
            setScreenSize({ currentSize: 'xs', isMobile: true, isSmallDesktop: false });
        } else if (isSM) {
            setScreenSize({ currentSize: 'sm', isMobile: true, isSmallDesktop: false });
        } else if (isMD) {
            setScreenSize({ currentSize: 'md', isMobile: false, isSmallDesktop: true });
        } else if (isLG) {
            setScreenSize({ currentSize: 'lg', isMobile: false, isSmallDesktop: true });
        } else if (isXL) {
            setScreenSize({ currentSize: 'xl', isMobile: false, isSmallDesktop: false });
        }

    }, [isXS, isSM, isMD, isLG, isXL]);

    return screenSize;
};