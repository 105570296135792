import React, { FunctionComponent } from 'react';
import './user-action-page-frame.scss';

import { Card, CardContent, CardActions, Container, Unstable_Grid2 as Grid, Alert } from '@mui/material';

import { Button, Form, FormInputsOutlet, AlertGroup } from '@dxlm/components';
import { IFormSubmitResult, IFormInput } from '@dxlm/components/form';

import dxMailLMLogo from '@dxlm/images/dx-mail-lm-logo.png';

interface IUserActionPageFrameProps {
    formInputs: IFormInput[];
    onSubmit: (form: IFormSubmitResult) => void;
    onInputChange: (value: any, name: string) => void;
    onInputEndIconButtonClick?: (e: React.MouseEvent) => void;
    errorMessages: string[];
    submitIcon: JSX.Element;
    submitText: string;
    isLoading: boolean;
    body?: JSX.Element;
    footer?: JSX.Element;
    disabled?: boolean;
    isPageError?: boolean;
}
const UserActionPageFrame: FunctionComponent<IUserActionPageFrameProps> = (props: IUserActionPageFrameProps) => {

    return (
        <Form
            className='user-action-page-frame-container flex-center full-page'
            inputs={props.formInputs}
            onSubmit={props.onSubmit}
            disabled={props.disabled}
        >
            <div className='background-image absolute-full-page'></div>
            <div className='image-overlay absolute-full-page'></div>

            <div className='user-action-page-frame-card-wrapper'>
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} display='flex' justifyContent='center'>
                                            <img className='center' src={dxMailLMLogo} height={80} width={257} alt='DX Mail Lodgement Manager' />
                                        </Grid>

                                        {props.body}

                                        {
                                            props.isPageError ? (
                                                <Grid xs={12} spacing={2}>
                                                    <Alert severity='error'>Something went wrong. Please try again later.</Alert>
                                                </Grid>
                                            ) : props.formInputs?.length > 0 ? (
                                                <Grid xs={12} spacing={2}>
                                                    <FormInputsOutlet
                                                        onInputChange={props.onInputChange}
                                                        onInputEndIconButtonClick={props.onInputEndIconButtonClick}
                                                    />
                                                </Grid>
                                            ) : null
                                        }
                                    </Grid>
                                </CardContent>
                                {
                                    !props.isPageError && props.formInputs?.length > 0 && (
                                        <CardActions>
                                            <Grid container spacing={2} flex='1'>
                                                {
                                                    props.errorMessages?.length > 0 && (
                                                        <Grid xs={12}>
                                                            <AlertGroup messages={props.errorMessages} severity='error' />
                                                        </Grid>
                                                    )
                                                }
                                                <Grid xs={12} display='flex'>
                                                    <Button icon={props.submitIcon} type='submit' loading={props.isLoading} style={{flex: '1'}}>
                                                        {props.submitText}
                                                    </Button>
                                                </Grid>
                                                {
                                                    props.footer && (
                                                        <Grid xs={12} textAlign='center'>
                                                            {props.footer}
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </CardActions>
                                    )
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Form>
    )
};

export default UserActionPageFrame;