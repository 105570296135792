import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { IAuthenticateUserRequest, IAuthenticateUserResponse, IChangePasswordRequest, ICreateOrUpdateUserRequest, IDefaultResponseMessage, ISetupUserAccountRequest, IUser, TStatus } from '@dxlm/interfaces';
import { HttpClientError, IQuerySettings, getQuerySettings, QueryKeys } from '@dxlm/models';
import { useHttpClient, useSnackbar } from '@dxlm/hooks';
import { OrganizationQueryKeys } from "@dxlm/hooks/api/OrganizationApi";

interface IRequestBase {
  organizationId: string;
  userId?: string;
}
interface IRequest<T> extends IRequestBase {
  data: T;
}

export enum UserQueryKeys {
  Self = 'Self'
}

export const useClearOrganizationUsersCache = () => {
  const queryClient = useQueryClient();

  return (organizationId: string) => {
    queryClient.invalidateQueries({
      predicate: (query) => Array.isArray(query.queryKey) && query.queryKey.length > 1 && query.queryKey[0] === QueryKeys.Users && query.queryKey[1] === organizationId
    })
  };
}

export const useGetUser = (organizationId: string, userId: string, settings?: IQuerySettings) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IUser, HttpClientError>([QueryKeys.Users, userId], () => {
        return GET<IUser>(`/v1/organizations/${organizationId}/users/${userId}`);
    }, {
      enabled: Boolean(userId) && Boolean(organizationId) && querySettings.enabled
    });
}

export const useGetCurrentUser = (settings?: IQuerySettings<IUser>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IUser, HttpClientError>([QueryKeys.Users, UserQueryKeys.Self], () => {
      return GET<IUser>(`/v1/users/self`, null, {
        autoShowErrorMessages: querySettings.autoShowErrorMessages,
        autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
      });
  }, {
    enabled: querySettings.enabled,
    onSuccess: querySettings.onSuccess,
    onError: querySettings.onError,
    staleTime: querySettings.staleTime
  });
}

export const useCreateUser = (navigateAfterCreate?: (userId: string) => string) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const clearOrganizationUsersCache = useClearOrganizationUsersCache();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createUser = ({ organizationId, data }: IRequest<ICreateOrUpdateUserRequest>) => {
    return POST<IUser>(`/v1/organizations/${organizationId}/users`, data);
  };

  return useMutation<IUser, HttpClientError, IRequest<ICreateOrUpdateUserRequest>>(createUser, {
    onSuccess: (response, input) => {
      clearOrganizationUsersCache(input.organizationId);
      queryClient.setQueryData([QueryKeys.Users, response.id], response);
      showSnackbar('User successfully created');

      if (navigateAfterCreate) {
        const navigateUrl = navigateAfterCreate(response.id);
        navigate(navigateUrl, { replace: true });
      }
    }
  });
}

export const useUpdateUser = () => {
  const { PUT } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const clearOrganizationUsersCache = useClearOrganizationUsersCache();
  const queryClient = useQueryClient();

  const updateUser = ({ organizationId, userId, data }: IRequest<ICreateOrUpdateUserRequest>) => {
    return PUT<IUser>(`/v1/organizations/${organizationId}/users/${userId}`, data);
  };

  return useMutation<IUser, HttpClientError, IRequest<ICreateOrUpdateUserRequest>>(updateUser, {
    onSuccess: (response, input) => {
      clearOrganizationUsersCache(input.organizationId);
      queryClient.setQueryData([QueryKeys.Users, response.id], response);
      showSnackbar('User successfully updated');
    }
  });
}

export const useDeleteUser = (navigateAfterDelete?: (userId: string) => string) => {
  const { DELETE } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const clearOrganizationUsersCache = useClearOrganizationUsersCache();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteUser = ({ organizationId, userId }: IRequestBase) => {
    return DELETE<IDefaultResponseMessage>(`/v1/organizations/${organizationId}/users/${userId}`);
  };

  return useMutation<IDefaultResponseMessage, HttpClientError, IRequestBase>(deleteUser, {
    onSuccess: (_, input) => {
      clearOrganizationUsersCache(input.organizationId);
      queryClient.invalidateQueries([QueryKeys.Users, input.userId]);
      showSnackbar('User successfully deleted');

      if (navigateAfterDelete) {
        const url = navigateAfterDelete(input.userId);
        navigate(url, { replace: true });
      }
    }
  });
}

export const useUpdateUserStatus = () => {
  const { PUT, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const clearOrganizationUsersCache = useClearOrganizationUsersCache();
  const queryClient = useQueryClient();

  const updateUserStatus = ({ organizationId, userId, data }: IRequest<TStatus>) => {
    return PUT<IUser>(buildUrl(`/v1/organizations/${organizationId}/users/${userId}/status`, { status: data }));
  };

  return useMutation<IUser, HttpClientError, IRequest<TStatus>>(updateUserStatus, {
    onSuccess: (response, input) => {
      clearOrganizationUsersCache(input.organizationId);
      queryClient.setQueryData([QueryKeys.Users, response.id], response);
      showSnackbar(`User successfully ${response.status === 'Disabled' ? 'disabled' : 'restored'}`);
    }
  });
}

export const useChangeUserPassword = () => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const changePassword = ({ organizationId, userId, data }: IRequest<IChangePasswordRequest>) => {
    return POST<IUser>(`/v1/organizations/${organizationId}/users/${userId}/change-password`, data);
  };

  return useMutation<IUser, HttpClientError, IRequest<IChangePasswordRequest>>(changePassword, {
    onSuccess: () => {
      showSnackbar('Password changed successfully.');
    }
  });
}

export const useResetUser2FA = (signOutAfterReset?: boolean) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const reset2FA = ({ organizationId, userId }: IRequestBase) => {
    return POST<IUser>(`/v1/organizations/${organizationId}/users/${userId}/reset-2fa`);
  };

  return useMutation<IUser, HttpClientError, IRequestBase>(reset2FA, {
    onSuccess: (response) => {
      showSnackbar('Two Factor Authentication successfully reset.');
      if (signOutAfterReset) {
        navigate(`/sign-out/${response.id}`);
      }
    }
  });
}

export const useSendUserPasswordResetEmail = () => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const sendPasswordResetEmail = (email: string) => {
    return POST<IDefaultResponseMessage>(buildUrl('/v1/users/send-password-reset', { email }));
  };

  return useMutation<IDefaultResponseMessage, HttpClientError, string>(sendPasswordResetEmail, {
    onSuccess: () => {
      showSnackbar('Password reset email was sent');
    }
  });
}

export const useAuthenticateUser = () => {
  const { POST, buildQueryParamString } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const authenticateUser = (request: IAuthenticateUserRequest) => {
    return POST<IAuthenticateUserResponse>(`/v1/users/auth`, request);
  };

  return useMutation<IAuthenticateUserResponse, HttpClientError, IAuthenticateUserRequest>(authenticateUser, {
    onSuccess: (response) => {
      if (response.authChallenge === 'EnableTwoFactor') {
        const queryParamString = buildQueryParamString({
          t: btoa(response.authChallengeToken),
          te: btoa(response.authChallengeExtraDetails),
          id: response.user.id,
          oid: response.organization.id
        })
        navigate(`/setup-2fa${queryParamString}`);
      } else if (response.authChallenge === 'VerifyTwoFactor') {
        const queryParamString = buildQueryParamString({
          id: response.user.id,
          oid: response.organization.id
        });
        navigate(`/verify-2fa${queryParamString}`);
      } else {
        showSnackbar(`Welcome ${response.user.name}`);
        queryClient.setQueryData([QueryKeys.Users, UserQueryKeys.Self], response.user);
        queryClient.setQueryData([QueryKeys.Organizations, OrganizationQueryKeys.Self], response.organization);
      }
    }
  });
}

interface SetupUserAccount {
  userId: string;
  request: ISetupUserAccountRequest;
}
export const useSetupUserAccount = () => {
  const { POST, buildQueryParamString } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const setupUserAccount = ({ request, userId }: SetupUserAccount) => {
    return POST<IAuthenticateUserResponse>(`/v1/users/${userId}/setup`, request);
  };

  return useMutation<IAuthenticateUserResponse, HttpClientError, SetupUserAccount>(setupUserAccount, {
    onSuccess: (response) => {
      if (response.authChallenge === 'EnableTwoFactor') {
        const queryParamString = buildQueryParamString({
          t: btoa(response.authChallengeToken),
          te: btoa(response.authChallengeExtraDetails),
          id: response.user.id,
          oid: response.organization.id
        })
        navigate(`/setup-2fa${queryParamString}`);
      } else if (response.authChallenge === 'VerifyTwoFactor') {
        const queryParamString = buildQueryParamString({
          id: response.user.id,
          oid: response.organization.id
        });
        navigate(`/verify-2fa${queryParamString}`);
      } else {
        showSnackbar(`Welcome ${response.user.name}`);
        queryClient.setQueryData([QueryKeys.Users, UserQueryKeys.Self], response.user);
        queryClient.setQueryData([QueryKeys.Organizations, OrganizationQueryKeys.Self], response.organization);
      }
    }
  });
}

interface ILink2Fa {
  organizationId: string;
  userId: string;
  code: string;
}
export const useLink2FA = () => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const link2FA = ({ organizationId, userId, code }: ILink2Fa) => {
    return POST<IAuthenticateUserResponse>(buildUrl(`/v1/organizations/${organizationId}/users/${userId}/link-2fa`, { code }));
  };

  return useMutation<IAuthenticateUserResponse, HttpClientError, ILink2Fa>(link2FA, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Users, UserQueryKeys.Self], response.user);
      queryClient.setQueryData([QueryKeys.Organizations, OrganizationQueryKeys.Self], response.organization);
      showSnackbar(`Welcome ${response.user.name}`);
    }
  });
}

interface IVerify2Fa {
  organizationId: string;
  userId: string;
  code: string;
  rememberDevice: boolean;
}
export const useVerify2FA = () => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const verify2FA = ({ organizationId, userId, code, rememberDevice }: IVerify2Fa) => {
    return POST<IAuthenticateUserResponse>(buildUrl(`/v1/organizations/${organizationId}/users/${userId}/verify-2fa`, { code, rememberDevice }));
  };

  return useMutation<IAuthenticateUserResponse, HttpClientError, IVerify2Fa>(verify2FA, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Users, UserQueryKeys.Self], response.user);
      queryClient.setQueryData([QueryKeys.Organizations, OrganizationQueryKeys.Self], response.organization);
      showSnackbar(`Welcome ${response.user.name}`);
    }
  });
}

export const useSignoutUser = () => {
  const { POST, buildUrl } = useHttpClient();
  const queryClient = useQueryClient();

  const signoutUser = ({ userId }: { userId: string }) => {
    return POST<IDefaultResponseMessage>(buildUrl('/v1/users/signout', { userId: userId }));
  };

  return useMutation<IDefaultResponseMessage, HttpClientError, { userId: string }>(signoutUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.Users, UserQueryKeys.Self]);
      queryClient.invalidateQueries([QueryKeys.Organizations, OrganizationQueryKeys.Self]);
    }
  });
}