import React, { FunctionComponent } from 'react';

import { FormControlLabel, Checkbox as MuiCheckbox, Typography } from '@mui/material';

interface ICheckboxProps {
    label?: string;
    value: boolean;
    name?: string;
    indeterminate?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
    size?: 'small' | 'medium';
}
const Checkbox: FunctionComponent<ICheckboxProps> = (props: ICheckboxProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange && !props.readOnly) {
            props.onChange(e.target.checked, e);
        }
    };

    return (
        <FormControlLabel
            label={props.label ? <Typography variant={props.size === 'small' ? 'body2' : 'body1'}>{props.label}</Typography> : null}
            sx={{
                marginRight: props.label ? null : '0px'
            }}
            control={
                <MuiCheckbox
                    onChange={handleChange}
                    readOnly={props.readOnly}
                    size={props.size}
                    checked={props.value}
                    indeterminate={props.indeterminate} />
            }
            name={props.name}
            disabled={props.disabled}
        />
    )
};

export default Checkbox;