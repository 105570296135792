import React, { FunctionComponent, useState, useRef } from 'react';

import FormContextProvider, { IFormChangedItem } from './form.provider';

import { IInputProps } from '@dxlm/components/input';
import { IMenuItem } from '@dxlm/interfaces';
import { DEFAULT_DATE_FORMAT } from '@dxlm/components/date-picker';

import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { isFormNameAnArray, parseFormInputName } from '@dxlm/formatters';

export type TFormInputControlType = 'input' | 'select' | 'switch' | 'checkbox' | 'textarea' | 'divider' | 'heading' | 'subHeading' | 'nested' | 'iconButton' | 'hidden' | 'date' | 'toggle' | 'radio';
export interface IFormInput extends IInputProps {
    validator?: <T>(value: any, formData: T) => boolean;
    controlType?: TFormInputControlType;
    options?: IMenuItem[];
    gridWidth?: number | { xs?: number, sm?: number, md?: number, lg?: number, xl?: number };
    children?: IFormInput[];
    childrenData?: any[];
    icon?: JSX.Element;
    iconButtonClicked?: (name: string) => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    shouldRender?: boolean;
    dateFormat?: string;
    color?: 'error' | 'warning' | 'success' | 'primary' | 'default'
    multiple?: boolean;
}
export interface IFormSubmitResult {
    getData: <T>(mappings?: {[key: string]: (data: any) => any}) => T;
    valid: boolean;
    invalidItems: string[];
}
interface IFormProps {
    className?: string;
    inputs: IFormInput[];
    formDataChanged?: (formData: any) => void;
    onSubmit: (form: IFormSubmitResult, e: React.FormEvent) => void;
    children: any;
    disabled?: boolean;
    readOnly?: boolean;
}
const Form: FunctionComponent<IFormProps> = (props: IFormProps) => {
    const [invalidFormInputs, setInvalidFormInputs] = useState<string[]>([]);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const checkIfStrongPassword = (val: string) => {
        if (!val) {
            return false;
        } else if (!val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+=-`])[A-Za-z\d~!@#$%^&*()_+=-`]{6,}$/g)) {
            return false;
        }
        return true;
    }

    const getInvalidEntries = (): string[]  => {
        if (!formRef.current) {
            return [];
        }

        const selfValidatedInvalidItems = Array.from(formRef.current.querySelectorAll(':invalid'))
            .map(x => x.getAttribute('name') ?? '');

        const customValidatedInvalidItems = Array.from(formRef.current.elements)
            .filter(x => x.hasAttribute('name'))
            .map(x => {
                const inputEl = x as HTMLInputElement;
                const matchingInput = props.inputs.find(x => x.name === inputEl.name);

                if (inputEl.value && matchingInput && matchingInput.enforceStrongPassword && !checkIfStrongPassword(inputEl.value) && selfValidatedInvalidItems.indexOf(inputEl.name) < 0) {
                    return inputEl.name;
                }

                if (!matchingInput || !matchingInput.validator || matchingInput.validator(inputEl.value, getFormData(null))) {
                    return null;
                }

                return selfValidatedInvalidItems.indexOf(inputEl.name) < 0 ? inputEl.name : null;
            })
            .filter(x => x?.length > 0);

        return [...selfValidatedInvalidItems, ...customValidatedInvalidItems];
    };

    const revalidateForm = () => {
        const invalidEntries = getInvalidEntries();
        setInvalidFormInputs(invalidEntries);
    };

    const handleInputChanged = (changedItem: IFormChangedItem) => {
        if (!props.formDataChanged) {
            return;
        }
        const currentFormData = getFormData(changedItem);
        props.formDataChanged(currentFormData);
    };

    const fixNestedPaths = (item: any) => {
        if (item && Array.isArray(item)) {
            for (const arrayItem of item) {
                fixNestedPaths(arrayItem);
            }
        } else if (item && typeof item === 'object') {
            for (const key in item) {
                if (item[key] && Array.isArray(item[key])) {
                    for (const arrayItem of item[key]) {
                        fixNestedPaths(arrayItem);
                    }
                } else if (typeof item[key] === 'object' && !isDayjs(item[key]) && item[key] !== null) {
                    fixNestedPaths(item[key]);
                } else if (key.indexOf('.') < 0) {
                    continue;
                } else {
                    let itemToChange = item;
                    const keyParts = key.split('.');
                    for (const keyPart of keyParts) {
                        const { itemName, itemIndex } = parseFormInputName(keyPart);

                        if (!itemName) {
                            continue;
                        }

                        if (isFormNameAnArray(keyPart)) {
                            if (itemIndex === null) {
                                itemToChange[itemName] = item[key];
                                continue;
                            }
    
                            if (!itemToChange[itemName]) {
                                itemToChange[itemName] = [{}];
                            }
                            
                            while (itemToChange[itemName].length <= itemIndex) {
                                itemToChange[itemName].push({});
                            }
                        } else if (!itemToChange[itemName]) {
                            itemToChange[itemName] = {};
                        }

                        if (keyParts.indexOf(keyPart) === keyParts.length - 1) {
                            itemToChange[itemName] = item[key];
                        } else if (!itemIndex && itemIndex !== 0) {
                            itemToChange = itemToChange[itemName];
                        } else {
                            itemToChange = itemToChange[itemName][itemIndex];
                        }
                    }

                    delete item[key];
                }
            }
        }

        return item;
    };

    const getFormData = (changedInputItem: IFormChangedItem) => {
        const formData = new FormData(formRef.current);
        const formEntries = Object.fromEntries(formData.entries()) as any;

        if (changedInputItem) {
            formEntries[changedInputItem.name] = changedInputItem.value;
        }

        props.inputs.filter(x => x.controlType === 'switch' || x.controlType === 'checkbox').forEach(switchInput => {
            const reportedValue = formEntries[switchInput.name];
            formEntries[switchInput.name] = reportedValue === 'on' || reportedValue === 'true' ? true : reportedValue === 'off' || reportedValue === 'false' ? false : reportedValue;
        });

        props.inputs.filter(x => x.controlType === 'date').forEach(dateInput => {
            const textValue = formEntries[dateInput.name];
            const parsedDate = textValue ? dayjs(textValue, dateInput.dateFormat ?? DEFAULT_DATE_FORMAT) : null;
            formEntries[dateInput.name] = parsedDate;
        });

        props.inputs.filter(x => (!x.controlType || x.controlType === 'input') && x.type === 'number').forEach(numberInput => {
            const textValue = formEntries[numberInput.name];
            const parsedNumber = textValue && !isNaN(parseFloat(textValue)) ? parseFloat(textValue) : null;
            formEntries[numberInput.name] = parsedNumber;
        });

        props.inputs.filter(x => x.controlType === 'select' && x.multiple).forEach(multipleSelectInput => {
            const rawValue = formEntries[multipleSelectInput.name];
            let arrayValue;
            if (!rawValue) {
                arrayValue = [];
            } else if (!Array.isArray(rawValue)) {
                arrayValue = rawValue.split(',');
            } else {
                arrayValue = rawValue;
            }
            formEntries[multipleSelectInput.name] = arrayValue;
        });

        return fixNestedPaths(formEntries);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!submitAttempted) {
            setSubmitAttempted(true);
        }

        if (!formRef.current) {
            return;
        }

        const invalidEntries = getInvalidEntries();

        if (invalidEntries.length > 0) {
            (formRef.current.querySelector(`[name="${invalidEntries[0]}"]`) as HTMLElement).focus();
        }

        props.onSubmit({
            getData: (mappings?: { [key: string]: (data: any) => any }) => {
                const dataResponse = getFormData(null);
                if (mappings) {
                    const dataResponseKeys = Object.keys(dataResponse);
                    for (const key in mappings) {
                        if (dataResponseKeys.includes(key) && mappings[key]) {
                            dataResponse[key] = mappings[key](dataResponse[key]);
                        }
                    }
                }
                return dataResponse;
            },
            invalidItems: invalidEntries,
            valid: invalidEntries.length < 1
        }, e);
        setInvalidFormInputs(invalidEntries);
    }
    
    return (
        <FormContextProvider
            inputs={props.inputs}
            invalidInputs={invalidFormInputs}
            submitAttempted={submitAttempted}
            onRevalidateRequested={revalidateForm}
            onFormDataChanged={handleInputChanged}
            disabled={props.disabled}
            readOnly={props.readOnly}
        >
            <form
                className={props.className}
                ref={formRef}
                noValidate={true}
                onSubmit={handleSubmit}
            >
                {props.children}
            </form>
        </FormContextProvider>
    )
};

export default Form;
