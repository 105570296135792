import React, { FunctionComponent } from 'react';

import { Tooltip as MuiTooltip } from '@mui/material';

interface IIconTooltipProps {
    text: string;
    icon: JSX.Element;
    fontSize?: string;
}
const IconTooltip: FunctionComponent<IIconTooltipProps> = (props: IIconTooltipProps) => {

    const iconClone = React.cloneElement(props.icon, {
        fontSize: 'inherit',
        color: 'disabled'
    });

    return (
        <MuiTooltip title={props.text} sx={{fontSize: props.fontSize ?? '1rem'}}>
            {iconClone}
        </MuiTooltip>
    )
};

export default IconTooltip;