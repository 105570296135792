import React, { FunctionComponent } from 'react';
import './rate-card.scss';

import { Stack } from '@mui/material';

import { IRateCard, IRateCardProduct, IRateCardProductSize, IRateCardProductSizeRate } from '@dxlm/interfaces';

import RateCardProductRate from './product-rate';

export type TRateCardType = 'global' | 'customer-discount' | 'customer';
interface IRateCardProps {
    rateCard: IRateCard;
    rateChanged: (product: IRateCardProduct, productSize: IRateCardProductSize, rate: IRateCardProductSizeRate, newRate: number) => void;
    disabled: boolean;
    mode: TRateCardType;
}
const RateCard: FunctionComponent<IRateCardProps> = (props: IRateCardProps) => {

    if (!props.rateCard || !props.rateCard.products || props.rateCard.products.length < 1) {
        return null;
    }

    return (
        <Stack direction='column' rowGap={4} className='rate-card'>
            {
                props.rateCard.products.map(p => (
                    <RateCardProductRate
                        key={p.id}
                        product={p}
                        aaps={props.rateCard.aaps}
                        zones={props.rateCard.zones}
                        rateChanged={(productSize, rate, newRate) => props.rateChanged(p, productSize, rate, newRate)}
                        disabled={props.disabled}
                        mode={props.mode}
                    />
                ))
            }
        </Stack>
    )
};

export default RateCard;