import React, { FunctionComponent, useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Stack } from '@mui/material';

import { Button, Checkbox, Form, FormInputsOutlet } from '@dxlm/components';
import { IFormInput, IFormSubmitResult } from '@dxlm/components/form';

interface IConfirmDialogProps {
    children?: any;
    open: boolean;
    title?: string;
    form?: IFormInput[];

    continueText?: string;
    cancelText?: string;

    acceptanceText?: string;
    acceptanceTexts?: string[];

    cancelClick?: (e: React.MouseEvent) => void;
    continueClick?: (e: React.MouseEvent) => void;
    formSubmitted?: (formData: IFormSubmitResult, e: React.FormEvent) => void;
}
const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props: IConfirmDialogProps) => {

    const [acceptedStates, setAcceptedStates] = useState<{[key: string]: boolean}>({});
    const [didAccept, setDidAccept] = useState(false);
    const [formData, setFormData] = useState<{[key: string]: any}>({});

    const handleAcceptanceTextCheckedChanged = (text: string, checked: boolean) => {
        const acceptedStatesCopy = Object.assign({}, acceptedStates);
        acceptedStatesCopy[text] = checked;
        setAcceptedStates(acceptedStatesCopy);
        setDidAccept(Object.keys(acceptedStatesCopy).filter(x => !acceptedStatesCopy[x]).length === 0);
    };

    const dialogBody = (dialogContent: JSX.Element) => (
        <>
            <DialogTitle>
                {props.title ?? 'Are you sure?'}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {dialogContent}
                    {
                        props.acceptanceText && (
                            <Checkbox size='small' label={props.acceptanceText} value={didAccept} onChange={(checked) => setDidAccept(checked)} />
                        )
                    }
                    {
                        props.acceptanceTexts?.length > 0 && (
                            <Stack spacing={0}>
                                {
                                    props.acceptanceTexts.map(text => (
                                        <Checkbox size='small' key={text} label={text} value={acceptedStates[text]} onChange={(checked) => handleAcceptanceTextCheckedChanged(text, checked)} />
                                    ))
                                }
                            </Stack>
                        )
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='error' onClick={props.cancelClick}>
                    {props.cancelText ?? 'Cancel'}
                </Button>
                <Button
                    variant='text'
                    autoFocus
                    onClick={props.form?.length > 0 ? null : props.continueClick}
                    type={props.form?.length > 0 ? 'submit' : 'button'}
                    disabled={!didAccept && (props.acceptanceText?.length > 0 || props.acceptanceTexts?.length > 0)}
                >
                    {props.continueText ?? 'Continue'}
                </Button>
            </DialogActions>
        </>
    );

    const dialogText = (
        <DialogContentText>
            {props.children}
        </DialogContentText>
    );

    const getDialogBodyWrapper = () => {
        if (!props.form || props.form.length < 1) {
            return dialogBody(dialogText);
        };

        const handleFormSubmit = (form: IFormSubmitResult, e: React.FormEvent) => {
            if (!form.valid || !props.formSubmitted || (!didAccept && props.acceptanceText)) {
                return;
            }

            props.formSubmitted(form, e);
        };

        const hydratedInputs: IFormInput[] = props.form
            .map(x => ({...x, value: formData[x.name]}));

        return (
            <Form
                inputs={hydratedInputs}
                formDataChanged={(fd) => setFormData(fd)}
                onSubmit={handleFormSubmit}
            >
                {dialogBody((
                    <Stack spacing={3}>
                        {dialogText}
                        <FormInputsOutlet />
                    </Stack>
                ))}
            </Form>
        )
    };

    useEffect(() => {
        setDidAccept(false);
        if (!props.form || props.form.length < 1) {
            return;
        }

        const extractedInitialFormData: {[key: string]: any} = {};
        for (const input of props.form) {
            extractedInitialFormData[input.name] = input.value;
        }

        setFormData(fd => ({...fd, ...extractedInitialFormData}));
    }, [props.form, props.open]);

    useEffect(() => {
        setAcceptedStates({});
        setDidAccept(false);
        if (!props.acceptanceTexts || props.acceptanceTexts.length < 1) {
            return;
        }

        const newAcceptedStates: {[key: string]: boolean} = {};
        for (const key of props.acceptanceTexts) {
            newAcceptedStates[key] = false;
        }
        setAcceptedStates(newAcceptedStates);
    }, [props.acceptanceTexts]);

    return (
        <Dialog open={props.open}>
            {getDialogBodyWrapper()}
        </Dialog>
    );
};

export default ConfirmDialog;