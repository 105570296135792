import React, { FunctionComponent } from 'react';

import { Tabs as MuiTabs, Box, Tab } from '@mui/material';

interface ITabsProps {
    activeIndex: number;
    options: React.ReactNode[];
    onChange: (newIndex: number) => void;
    disabled?: boolean;
}
const Tabs: FunctionComponent<ITabsProps> = (props: ITabsProps) => {

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <MuiTabs
                value={props.activeIndex}
                onChange={(e, newVal) => props.onChange(newVal)}
                variant='scrollable'
                scrollButtons='auto'
                disabled={props.disabled}
            >
                {
                    props.options.map((option, index) => (
                        <Tab key={index} label={option} disabled={props.disabled} />
                    ))
                }
            </MuiTabs>
        </Box>
    )
};

export default Tabs;