import React from 'react';

import { AppSettingsContext, HttpClientContext, AuthContext, PageTitleContext, SnackbarContext } from '@dxlm/contexts';

export * from "./file-uploader";
export * from "./screen-size";
export * from "./loading-manager";

export const useAppSettings = () => React.useContext(AppSettingsContext);
export const useHttpClient = () => React.useContext(HttpClientContext);
export const useAuth = () => React.useContext(AuthContext);
export const usePageTitle = () => React.useContext(PageTitleContext);
export const useSnackbar = () => React.useContext(SnackbarContext);