import React, { FunctionComponent } from 'react';

import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@mui/material';
import { IMenuItem } from '@dxlm/interfaces';

interface IRadioGroupProps {
    value: string;
    onChange: (newVal: string) => void;
    options: IMenuItem[];
    label?: string;
    disabled?: boolean;
    name?: string;
}
const RadioGroup: FunctionComponent<IRadioGroupProps> = (props: IRadioGroupProps) => {

    return (
        <FormControl
            disabled={props.disabled}
        >
            {
                props.label?.length > 0 && (
                    <FormLabel>{props.label}</FormLabel>
                )
            }

            <MuiRadioGroup
                value={props.value}
                onChange={(e, v) => props.onChange(v)}
                name={props.name}
                row
            >
                {
                    props.options.map(option => (
                        <FormControlLabel
                            key={option.value ?? option.text}
                            value={option.value ?? option.text}
                            control={<Radio disabled={props.disabled} />}
                            label={option.text}
                        />
                    ))
                }
            </MuiRadioGroup>
        </FormControl>
    )
};

export default RadioGroup;