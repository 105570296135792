import React, { FunctionComponent } from 'react';

import { ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import { IMenuItem } from '@dxlm/interfaces';

interface IToggleButtonProps {
    options: IMenuItem[];
    value: string;
    disabled?: boolean;
    name?: string;
    onChange: (newValue: string) => void;
}
const ToggleButton: FunctionComponent<IToggleButtonProps> = (props: IToggleButtonProps) => {

    return (
        <MuiToggleButtonGroup
            color='primary'
            value={props.value}
            exclusive
            onChange={(e, newVal) => props.onChange(newVal)}
            disabled={props.disabled}
            size='large'
        >
            {
                props.options.map(x => (
                    <MuiToggleButton
                        disabled={props.disabled}
                        value={x.value ?? x.text}
                        key={x.value ?? x.text}
                    >
                        {x.text}
                    </MuiToggleButton>
                ))
            }

            {
                props.name && (
                    <input name={props.name} value={props.value} hidden />
                )
            }
        </MuiToggleButtonGroup>
    )
};

export default ToggleButton;