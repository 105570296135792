import React, { FunctionComponent } from 'react';

import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';

interface ISwitchProps {
    label: string;
    value: boolean;
    name?: string;
    disabled?: boolean;
    onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
}
const Switch: FunctionComponent<ISwitchProps> = (props: ISwitchProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e.target.checked, e);
        }
    };

    return (
        <FormControlLabel
            label={props.label}
            control={
                <MuiSwitch onChange={handleChange} checked={props.value} />
            }
            name={props.name}
            disabled={props.disabled}
        />
    )
};

export default Switch;