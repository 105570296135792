import React, { FunctionComponent, useMemo } from 'react';

import { useFormContext } from '@dxlm/components/form/form.provider';

import { Unstable_Grid2 as Grid, SelectChangeEvent, Divider, Typography, SxProps } from '@mui/material';
import { Theme } from '@mui/system';

import { IFormInput } from '@dxlm/components/form';
import { Input, Switch, Checkbox, Select, Button, DatePicker, ToggleButton, RadioGroup } from '@dxlm/components';
import { Dayjs } from 'dayjs';

interface IInputGridWrapperProps {
    inputProps: IFormInput;
    uniqueName: string;
    alignItems?: string;
    justifyContent?: string;
    display?: string;
    sx?: SxProps<Theme>
    isContainer?: boolean;
    children: any;
}
const InputGridWrapper: FunctionComponent<IInputGridWrapperProps> = (props: IInputGridWrapperProps) => {
    const getGridSize = (inputProps: IFormInput, size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): number => {
        if (!inputProps.gridWidth || typeof inputProps.gridWidth === 'number') {
            return inputProps.gridWidth as number ?? 12;
        }

        return (inputProps.gridWidth as any)[size];
    };

    return (
        <Grid
            key={props.uniqueName}
            xs={getGridSize(props.inputProps, 'xs')}
            sm={getGridSize(props.inputProps, 'sm')}
            md={getGridSize(props.inputProps, 'md')}
            lg={getGridSize(props.inputProps, 'lg')}
            xl={getGridSize(props.inputProps, 'xl')}
            alignItems={props.alignItems}
            justifyContent={props.justifyContent}
            display={props.display}
            sx={props.sx}
            container={props.isContainer}
        >
            {props.children}
        </Grid>
    );
};

interface IFormInputOutletProps {
    onInputChange?: (value: any, name: string) => void;
    onInputStartIconButtonClick?: (e: React.MouseEvent) => void;
    onInputEndIconButtonClick?: (e: React.MouseEvent) => void;
    mainGridPadding?: number;
    inputNamesToRender?: string[];
}
const FormInputsOutlet: FunctionComponent<IFormInputOutletProps> = (props: IFormInputOutletProps) => {
    const formContext = useFormContext();
    
    const inputsToRender: IFormInput[] = useMemo(() => {
        return formContext.inputs
            .filter(x => x.shouldRender !== false)
            .filter(x => !props.inputNamesToRender || props.inputNamesToRender.findIndex(y => y === x.name) >= 0);
    }, [formContext.inputs, props.inputNamesToRender]);

    if (!formContext.inputs || formContext.inputs.length < 1) {
        return null;
    }

    const getInputUniqueName = (inputProps: IFormInput, index: number = null) =>
        `${(inputProps.name ?? inputProps.label)}${index !== null && index >= 0 ? (`[${index}]`) : ''}`;

    const getNestedInputUniqueName = (parentName: string, inputProps: IFormInput, inputIndex: number = null) =>
        `${parentName}.${getInputUniqueName(inputProps, inputIndex)}`;

    const buildInput = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const isError = formContext.submitAttempted && formContext.invalidInputs.indexOf(uniqueName) > -1;
        const onChangeOverride = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formContext.inputChanged({ value: e.target.value, name: e.target.name });

            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(e.target.value, e.target.name);
            }

            if (inputProps.onChange && typeof inputProps.onChange === 'function') {
                inputProps.onChange(e);
            }
        };

        const shouldAddStartIconClickEvents =
            (props.onInputStartIconButtonClick && typeof props.onInputStartIconButtonClick === 'function') ||
            (inputProps.startIconIsButton && inputProps.startIconButtonClicked && typeof inputProps.startIconButtonClicked === 'function');
        const onStartIconButtonClickOverride = (e: React.MouseEvent) => {
            if (props.onInputStartIconButtonClick && typeof props.onInputStartIconButtonClick === 'function') {
                props.onInputStartIconButtonClick(e);
            }

            if (inputProps.startIconButtonClicked && typeof inputProps.startIconButtonClicked === 'function') {
                inputProps.startIconButtonClicked(e);
            }
        };

        const shouldAddEndIconClickEvents =
            (props.onInputEndIconButtonClick && typeof props.onInputEndIconButtonClick === 'function') ||
            (inputProps.endIconIsButton && inputProps.endIconButtonClicked && typeof inputProps.endIconButtonClicked === 'function');
        const onEndIconButtonClickOverride = (e: React.MouseEvent) => {
            if (props.onInputEndIconButtonClick && typeof props.onInputEndIconButtonClick === 'function') {
                props.onInputEndIconButtonClick(e);
            }

            if (inputProps.endIconButtonClicked && typeof inputProps.endIconButtonClicked === 'function') {
                inputProps.endIconButtonClicked(e);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Input {...inputProps}
                    label={`${inputProps.label}${!inputProps.required ? ' (Optional)' : ''}`}
                    error={isError}
                    name={uniqueName}
                    onChange={onChangeOverride}
                    onKeyUp={() => formContext.reValidate()}
                    startIconButtonClicked={shouldAddStartIconClickEvents ? onStartIconButtonClickOverride : null}
                    endIconButtonClicked={shouldAddEndIconClickEvents ? onEndIconButtonClickOverride : null}
                    disabled={inputProps.disabled || formContext.disabled}
                    multiline={inputProps.controlType === 'textarea'}
                    rows={inputProps.controlType === 'textarea' ? (inputProps.rows ?? 5) : null}
                    readOnly={inputProps.readOnly || formContext.readOnly} />
            </InputGridWrapper>
        );
    };

    const buildToggleButton = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const onChangeOverride = (newVal: string) => {
            formContext.inputChanged({ value: newVal, name: uniqueName });

            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(newVal, uniqueName);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
                sx={{
                    marginTop: '16px',
                    marginBottom: '8px'
                }}
            >
                <ToggleButton
                    name={uniqueName}
                    disabled={inputProps.disabled || formContext.disabled || inputProps.readOnly  || formContext.readOnly}
                    value={inputProps.value}
                    options={inputProps.options}
                    onChange={onChangeOverride}
                />
            </InputGridWrapper>
        );
    };

    const buildRadioGroup = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const onChangeOverride = (newVal: string) => {
            formContext.inputChanged({ value: newVal, name: uniqueName });

            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(newVal, uniqueName);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <RadioGroup
                    name={uniqueName}
                    disabled={inputProps.disabled || formContext.disabled || inputProps.readOnly  || formContext.readOnly}
                    value={inputProps.value}
                    options={inputProps.options}
                    onChange={onChangeOverride}
                />
            </InputGridWrapper>
        );
    };

    const buildSwitch = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const onChangeOverride = (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => {
            formContext.inputChanged({ value: checked, name: e.target.name });

            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(checked, e.target.name);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Switch
                    label={inputProps.label}
                    name={uniqueName}
                    disabled={inputProps.disabled || formContext.disabled || inputProps.readOnly || formContext.readOnly}
                    value={inputProps.value}
                    onChange={onChangeOverride}
                />
            </InputGridWrapper>
        )
    };

    const buildCheckbox = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const onChangeOverride = (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => {
            formContext.inputChanged({ value: checked, name: e.target.name });

            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(checked, e.target.name);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Checkbox
                    label={inputProps.label}
                    name={uniqueName}
                    disabled={inputProps.disabled || formContext.disabled || inputProps.readOnly || formContext.readOnly}
                    value={inputProps.value}
                    onChange={onChangeOverride}
                />
            </InputGridWrapper>
        )
    };

    const buildSelect = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const isError = formContext.submitAttempted && formContext.invalidInputs.indexOf(uniqueName) > -1;
        const onChangeOverride = (e: SelectChangeEvent<string>) => {
            formContext.inputChanged({ value: e.target.value, name: e.target.name });
            
            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(e.target.value, e.target.name);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Select
                    label={inputProps.label}
                    value={inputProps.value}
                    name={uniqueName}
                    onChange={onChangeOverride}
                    options={inputProps.options}
                    disabled={inputProps.disabled || formContext.disabled}
                    error={isError}
                    required={inputProps.required}
                    startIcon={inputProps.startIcon}
                    readOnly={inputProps.readOnly || formContext.readOnly}
                    multiple={inputProps.multiple}
                />
            </InputGridWrapper>
        );
    };

    const buildDatePicker = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        const isError = formContext.submitAttempted && formContext.invalidInputs.indexOf(uniqueName) > -1;
        const onChangeOverride = (newVal: Dayjs) => {
            formContext.inputChanged({ value: newVal, name: uniqueName });
            
            if (props.onInputChange && typeof props.onInputChange === 'function') {
                props.onInputChange(newVal, uniqueName);
            }
        };

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <DatePicker
                    label={inputProps.label}
                    value={inputProps.value}
                    name={uniqueName}
                    onChange={onChangeOverride}
                    disabled={inputProps.disabled || formContext.disabled}
                    error={isError}
                    required={inputProps.required}
                    minDate={inputProps.minDate}
                    maxDate={inputProps.maxDate}
                    dateFormat={inputProps.dateFormat}
                    readOnly={inputProps.readOnly || formContext.readOnly}
                />
            </InputGridWrapper>
        );
    };

    const buildNestedInputs = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        return (
            <InputGridWrapper
                key={parentUniqueName}
                uniqueName={parentUniqueName}
                inputProps={inputProps}
                isContainer={true}
            >
                {
                    inputProps.childrenData.map((childData, index) => {
                        const uniqueName = parentUniqueName
                            ? getNestedInputUniqueName(parentUniqueName, inputProps, index)
                            : getInputUniqueName(inputProps, index);
            
                        return inputProps.children.map((childInputProps) => 
                            getInput({...childInputProps, value: childData[getInputUniqueName(childInputProps)]}, uniqueName)
                        ).flatMap(x => x);
                    }).flatMap(x => x)
                }
            </InputGridWrapper>
        )
    };

    const buildIconButton = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Button
                    iconOnly
                    icon={inputProps.icon}
                    disabled={inputProps.disabled || formContext.disabled || inputProps.readOnly || formContext.readOnly}
                    onClick={() => inputProps.iconButtonClicked ? inputProps.iconButtonClicked(uniqueName) : null}
                />
            </InputGridWrapper>
        )
    };

    const buildHiddenInput = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        return (
            <input
                key={uniqueName}
                name={uniqueName}
                value={inputProps.value}
                readOnly
                hidden
            />
        )
    };

    const buildDivider = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Divider style={{ paddingTop: '20px', marginBottom: '20px' }} />
            </InputGridWrapper>
        );
    };

    const buildHeading = (inputProps: IFormInput, parentUniqueName: string): JSX.Element => {
        const uniqueName = parentUniqueName
            ? getNestedInputUniqueName(parentUniqueName, inputProps)
            : getInputUniqueName(inputProps);

        return (
            <InputGridWrapper
                key={uniqueName}
                uniqueName={uniqueName}
                inputProps={inputProps}
            >
                <Typography
                    variant={inputProps.controlType === 'subHeading' ? 'subtitle1' : 'h6'}
                    color={inputProps.color}
                    sx={{marginBottom: inputProps.controlType === 'subHeading' ? '3px' : '5px', marginTop: inputProps.controlType === 'subHeading' ? '8px' : '0px' }}
                >
                    {inputProps.label}
                </Typography>
            </InputGridWrapper>
        );
    };

    const getInput = (inputProps: IFormInput, parentUniqueName: string = null): JSX.Element | JSX.Element[] => {
        switch (inputProps.controlType) {
            case 'select': return buildSelect(inputProps, parentUniqueName);
            case 'switch': return buildSwitch(inputProps, parentUniqueName);
            case 'checkbox': return buildCheckbox(inputProps, parentUniqueName);
            case 'divider': return buildDivider(inputProps, parentUniqueName);
            case 'heading': return buildHeading(inputProps, parentUniqueName);
            case 'subHeading': return buildHeading(inputProps, parentUniqueName);
            case 'nested': return buildNestedInputs(inputProps, parentUniqueName);
            case 'iconButton': return buildIconButton(inputProps, parentUniqueName);
            case 'hidden': return buildHiddenInput(inputProps, parentUniqueName);
            case 'date': return buildDatePicker(inputProps, parentUniqueName);
            case 'toggle': return buildToggleButton(inputProps, parentUniqueName);
            case 'radio': return buildRadioGroup(inputProps, parentUniqueName);
            default: return buildInput(inputProps, parentUniqueName);
        }
    }

    return (
        <Grid container rowSpacing={0} columnSpacing={2} flexDirection='row' padding={props.mainGridPadding}>
            {
                inputsToRender.map(formInput => getInput(formInput))
            }
        </Grid>
    )
};

export default FormInputsOutlet;