import React, { FunctionComponent, useRef, useMemo, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { AttachMoney, Calculate, Percent, Warning } from '@mui/icons-material';

import { IRateCardProductSize, IRateCardProductSizeRate, IRateCardZone } from '@dxlm/interfaces';
import { useScreenSize } from '@dxlm/hooks';
import { ConfirmDialog, Input } from '@dxlm/components';
import { TRateCardType } from '.';

import RateCalculator from './rate-calculator';
import { formatCurrency } from '@dxlm/formatters';

interface IRateCardSizeRateRow {
    zoneName: string;
    rates: IRateCardProductSizeRate[];
}

const DECIMAL_POINT_ROUNDING = 6;

interface IRateCardProductSizeRateProps {
    productSize: IRateCardProductSize;
    zones: IRateCardZone[];
    columnWidth: number;
    onScroll: (scrollLeft: number) => void;
    scrollHeaderLoaded: (scrollHeader: HTMLDivElement) => void;
    scrollBodyLoaded: (scrollBody: HTMLDivElement) => void;
    rateChanged: (rate: IRateCardProductSizeRate, newRate: number) => void;
    disabled: boolean;
    mode: TRateCardType;
}
const RateCardProductSizeRate: FunctionComponent<IRateCardProductSizeRateProps> = (props: IRateCardProductSizeRateProps) => {

    const { isMobile } = useScreenSize();
    const navigate = useNavigate();

    const scrollingHeaderRef = useRef<HTMLDivElement>();
    const scrollingBodyRef = useRef<HTMLDivElement>();

    const [warnRatesNotSetOpen, setWarnRatesNotSetOpen] = useState(false);
    const [calculatorOpenRate, setCalculatorOpenRate] = useState<IRateCardProductSizeRate>(null);
    const isCalculatorOpen = Boolean(calculatorOpenRate);

    const uniqueRateCodes: string[] = useMemo<string[]>(() => {
        const rateCodes: string[] = [];
        for (const rate of props.productSize.rates) {
            if (rateCodes.indexOf(rate.code) > -1) {
                continue;
            }
            rateCodes.push(rate.code);
        }
        return rateCodes;
    }, [props.productSize]);

    const rateRows: IRateCardSizeRateRow[] = useMemo<IRateCardSizeRateRow[]>(() => {
        const newRateRows: IRateCardSizeRateRow[] = [];
        for (const rate of props.productSize.rates) {
            let zoneName = rate.rateType as string;
            switch (rate.rateType) {
                case 'ZoneA':
                    zoneName = 'Zone A';
                    break;
                case 'NZPost':
                    zoneName = props.zones.find(y => y.id === rate.zoneId).name;
                    break;
                case 'DXMailRural': 
                    zoneName = 'DX Mail Rural';
                    break;
                case 'DXMail': 
                    zoneName = 'DX Mail';
                    break;
            }
            
            const existingRow = newRateRows.find(x => x.zoneName === zoneName);
            if (existingRow) {
                existingRow.rates.push(rate);
            } else {
                newRateRows.push({
                    zoneName,
                    rates: [rate]
                });
            }
        }
        return newRateRows;
    }, [props.productSize]);

    const handleHeaderScroll = (e: React.UIEvent) => {
        props.onScroll(scrollingHeaderRef.current.scrollLeft);
    };
    const handleBodyScroll = (e: React.UIEvent) => {
        props.onScroll(scrollingBodyRef.current.scrollLeft);
    };

    const handleRateChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rate: IRateCardProductSizeRate) => {
        props.rateChanged(rate, parseFloat(e.target.value));
    };

    const handleCalculatedRateSelected = (newCalculatedRate: number, newDiscountPercentage: number) => {
        props.rateChanged(calculatorOpenRate, newDiscountPercentage);
        setCalculatorOpenRate(null);
    };

    const calculateDiscountedRate = (rate: number, discountPercentage: number) => Math.ceil((rate - (rate * (discountPercentage / 100))) * Math.pow(10, DECIMAL_POINT_ROUNDING)) / Math.pow(10, DECIMAL_POINT_ROUNDING);

    const columnHeadings: string[] = [
        'Product Code:',
        ...uniqueRateCodes
    ];

    useEffect(() => {
        if (scrollingHeaderRef.current) {
            props.scrollHeaderLoaded(scrollingHeaderRef.current);
        }
        if (scrollingBodyRef.current) {
            props.scrollBodyLoaded(scrollingBodyRef.current);
        }

    }, [scrollingBodyRef.current, scrollingHeaderRef.current]);

    return (
        <>
            {
                !isMobile && (
                    <Grid xs={12} md={4} className='rate-card-shade-back rate-card-bottom-border dense-grid'>
                        <Stack  flexDirection='row' spacing={0}>
                            <Grid xs={3} padding={0}>
                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                    Size
                                </Typography>
                            </Grid>
                            <Grid xs={9} padding={0}>
                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                    Dimensions (H x L x W)
                                </Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                )
            }
            <Grid xs={12} md={8} className='rate-card-shade-back rate-card-bottom-border dense-grid'>
                <div
                    className='rate-card-grid-scroller'
                    ref={scrollingHeaderRef}
                    onScroll={handleHeaderScroll}
                >
                    <Stack direction='row' flex='1'>
                        {
                            columnHeadings.map(x => (
                                <Typography
                                    key={x}
                                    variant='body2'
                                    className='rate-card-product-column'
                                    sx={{
                                        fontWeight: 'bold',
                                        minWidth: `${props.columnWidth}px`
                                    }}
                                >
                                    {x}
                                </Typography>
                            ))
                        }
                    </Stack>
                </div>
            </Grid>

            {
                !isMobile && (
                    <Grid xs={12} md={4} className='dense-grid'>
                        <Stack  flexDirection='row' spacing={0}>
                            <Grid xs={3} padding={0}>
                                <Typography variant='body2'>
                                    {props.productSize.name}
                                </Typography>
                            </Grid>
                            <Grid xs={9} padding={0}>
                                <Typography variant='body2'>
                                    {props.productSize.description}
                                </Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                )
            }
            <Grid xs={12} md={8} padding={0}>
                <div
                    className='rate-card-grid-scroller'
                    ref={scrollingBodyRef}
                    onScroll={handleBodyScroll}
                >
                    <Stack direction='column' spacing={0} flex='1'>
                        {
                            rateRows.map((rr, index, allItems) => (
                                <Stack
                                    key={rr.zoneName}
                                    direction='row'
                                    flex='1'
                                    className={index < allItems.length - 1 ? 'rate-card-bottom-border' : ''}>
                                    <Typography
                                        variant='body2'
                                        className='rate-card-product-column'
                                        sx={{
                                            minWidth: `${props.columnWidth}px`,
                                            padding: '10px 0px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        {rr.zoneName}
                                    </Typography>
                                    {
                                        rr.rates.map((r, index, allItems) => (
                                            <div
                                                key={r.code}
                                                className={`rate-card-product-column rate-card-rate-input ${r.rate > 0 || props.mode === 'customer-discount' ? 'valid' : 'invalid'} ${props.mode}`}
                                                style={{
                                                    minWidth: `${props.columnWidth}px`,
                                                    paddingRight: index === allItems.length - 1 ? '10px' : '0px'
                                                }}
                                            >
                                                {
                                                    props.mode === 'global' ? (
                                                        <Input
                                                            className='native-look'
                                                            type='number'
                                                            value={r.rate}
                                                            min={0}
                                                            size='small'
                                                            onChange={(e => handleRateChanged(e, r))}
                                                            disabled={props.disabled}
                                                            startIcon={<AttachMoney fontSize='small' />}
                                                        />
                                                    ) : props.mode === 'customer-discount' ? (
                                                        <Input
                                                            className='native-look'
                                                            type='number'
                                                            value={r.discountPercentage}
                                                            size='small'
                                                            min={0}
                                                            max={100}
                                                            onChange={(e => handleRateChanged(e, r))}
                                                            disabled={props.disabled}
                                                            readOnly={r.rate <= 0}
                                                            startIconIsButton={true}
                                                            startIconButtonClicked={() => props.disabled ? null : r.rate > 0 ? setCalculatorOpenRate(r) : setWarnRatesNotSetOpen(true)}
                                                            startIcon={r.rate > 0 ? <Calculate fontSize='small' /> : <Warning fontSize='small' color='warning' />}
                                                            endIcon={<Percent fontSize='small' />}
                                                        />
                                                    ) : (
                                                        <Typography variant='body2' display='flex' alignItems='center' columnGap='3px'>
                                                            {formatCurrency(calculateDiscountedRate(r.rate, r.discountPercentage), 'PRECISE')}
                                                        </Typography>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </Stack>
                            ))
                        }
                    </Stack>
                </div>
            </Grid>

            <RateCalculator
                open={isCalculatorOpen}
                onClose={() => setCalculatorOpenRate(null)}
                baseRate={calculatorOpenRate?.rate}
                discountPercentage={calculatorOpenRate?.discountPercentage}
                onRateSelected={handleCalculatedRateSelected}
            />

            <ConfirmDialog
                open={warnRatesNotSetOpen}
                cancelClick={() => setWarnRatesNotSetOpen(false)}
                continueClick={() => navigate('/dashboard/system-configuration/global-rate-card')}
                title='Rate Not Set'
                cancelText='Close'
                continueText='Go to Global Rates'
            >
                Rates has not yet been set for this item. Please set up the global rates for this item before discounting.
            </ConfirmDialog>
        </>
    )
};

export default RateCardProductSizeRate;