import React, { FunctionComponent, useEffect, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import PageLoaderPage from '@dxlm/pages/page-loader/PageLoaderPage';
import { PageTitleContextProvider } from '@dxlm/contexts';
import { useAuth, usePageTitle } from '@dxlm/hooks';
import { IUser, TUserRole } from '@dxlm/interfaces';

interface IProtectedRouteProps {
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser) => boolean;
    children: any;
}
const ProtectedRoute: FunctionComponent<IProtectedRouteProps> = (props: IProtectedRouteProps) => {
    const {
        allowedRoles,
        isAllowed,
        children
    } = props;

    const { user, isInitialized, userHasRole } = useAuth();

    if (!isInitialized) {
        return null;
    }
    
    if (
        !user?.id ||
        (allowedRoles?.length > 0 && !userHasRole(...allowedRoles)) ||
        (isAllowed && typeof isAllowed === 'function' && !isAllowed(user))
    ) {
        return <Navigate to={`/sign-out/${user?.id ?? null}?reauth=true`} replace />
    }

    return children;
};

interface ILazyLoaderProps extends IProtectedRouteProps {
    title?: string;
    noTitle?: boolean;

    protected?: boolean;
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser) => boolean;

    children: any;
}

const TITLE_SUFFIX = 'DX Mail Lodgement Manager';
const LazyLoaderPageWrapper: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    const pageTitleContext = usePageTitle();

    useEffect(() => {
        if (props.noTitle) {
            return;
        }

        let newPageTitle = TITLE_SUFFIX;
        if (props.title) {
            newPageTitle = `${props.title} | ${TITLE_SUFFIX}`;
        }

        pageTitleContext.setPageTitle(newPageTitle);
    }, [props.title]);

    if (props.protected || props.allowedRoles?.length > 0 || (props.isAllowed && typeof props.isAllowed === 'function')) {
        return (
            <ProtectedRoute allowedRoles={props.allowedRoles} isAllowed={props.isAllowed}>
                {props.children}
            </ProtectedRoute>
        )
    }

    return props.children;
};

const LazyLoader: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    return (
        <Suspense fallback={<PageLoaderPage />}>
            <PageTitleContextProvider>
                <LazyLoaderPageWrapper {...props} />
            </PageTitleContextProvider>
        </Suspense>
    )
};

export default LazyLoader;