import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { IReducerAction } from '@dxlm/interfaces';

import { IFormInput } from '@dxlm/components/form';

export interface IFormChangedItem {
    value: any;
    name: string;
}
interface IFormContext {
    inputs: IFormInput[];
    invalidInputs: string[];
    submitAttempted: boolean;
    disabled: boolean;
    readOnly: boolean;

    changedItem?: IFormChangedItem;
    formState?: string;

    reValidate?: () => void;
    inputChanged?: (changedItem: IFormChangedItem) => void;
}

const initialState: IFormContext = {
    inputs: [],
    invalidInputs: [],
    submitAttempted: false,
    disabled: false,
    readOnly: false
};
const FormContext = React.createContext<IFormContext>(initialState);

type TFormActions = 
    'SET_INPUTS' |
    'SET_INVALID_INPUTS' |
    'SET_SUBMIT_ATTEMPTED' |
    'RE_VALIDATE' |
    'SET_DISABLED_STATE' |
    'SET_READONLY_STATE' |
    'INPUT_CHANGED';

const FormContextReducer = (state: IFormContext, action: IReducerAction<TFormActions>): IFormContext => {
    switch (action.type) {
        case 'SET_INPUTS':
            return { ...state, inputs: action.payload };

        case 'SET_INVALID_INPUTS':
            return { ...state, invalidInputs: action.payload };

        case 'SET_SUBMIT_ATTEMPTED':
            return { ...state, submitAttempted: action.payload };

        case 'SET_DISABLED_STATE':
            return { ...state, disabled: action.payload };

        case 'SET_READONLY_STATE':
            return { ...state, readOnly: action.payload };

        case 'RE_VALIDATE':
            return { ...state, formState: new Date().getTime().toString() };

        case 'INPUT_CHANGED':
            return { ...state, changedItem: action.payload };

        default:
            return state;
    }
};

interface IFormProviderProps {
    inputs?: IFormInput[];
    invalidInputs?: string[];
    submitAttempted?: boolean;
    onRevalidateRequested?: () => void;
    onFormDataChanged?: (changedItem: IFormChangedItem) => void;
    children?: any;
    disabled?: boolean;
    readOnly?: boolean;
}
const FormContextProvider: FunctionComponent<IFormProviderProps> = (props: IFormProviderProps) => {
    const [state, dispatch] = useReducer(FormContextReducer, {...initialState,
        reValidate: () => dispatch({ type: 'RE_VALIDATE' }),
        inputChanged: (changedItem: IFormChangedItem) => dispatch({ type: 'INPUT_CHANGED', payload: changedItem })
    });

    const {
        onRevalidateRequested,
        onFormDataChanged
    } = props;

    useEffect(() => {
        dispatch({
            type: 'SET_INPUTS',
            payload: props.inputs
        });
    }, [ props.inputs ]);

    useEffect(() => {
        dispatch({
            type: 'SET_INVALID_INPUTS',
            payload: props.invalidInputs
        });
    }, [ props.invalidInputs ]);

    useEffect(() => {
        dispatch({
            type: 'SET_SUBMIT_ATTEMPTED',
            payload: props.submitAttempted
        });
    }, [ props.submitAttempted ]);

    useEffect(() => {
        dispatch({
            type: 'SET_DISABLED_STATE',
            payload: props.disabled
        });
    }, [ props.disabled ]);

    useEffect(() => {
        dispatch({
            type: 'SET_READONLY_STATE',
            payload: props.readOnly
        });
    }, [ props.readOnly ]);

    useEffect(() => {
        if (onRevalidateRequested) {
            onRevalidateRequested();
        }
    }, [state.formState]);

    useEffect(() => {
        if (state.changedItem && onFormDataChanged) {
            onFormDataChanged(state.changedItem);
        }
    }, [state.changedItem]);

    return (
        <FormContext.Provider value={state}>
            {props.children}
        </FormContext.Provider>
    )
};

export const useFormContext = () => React.useContext(FormContext);

export default FormContextProvider;