import React, { FunctionComponent, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { LazyLoader } from '@dxlm/components';

const LoginPage = lazy(() => import('./pages/login/LoginPage'));
const SignOutPage = lazy(() => import('./pages/sign-out/SignOutPage'));
const UserSetupPage = lazy(() => import('./pages/user-setup/UserSetupPage'));
const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));
const OrganizationsPage = lazy(() => import('./pages/organizations/OrganizationsPage'));
const OrganizationPage = lazy(() => import('./pages/organization/OrganizationPage'));
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const UserPage = lazy(() => import('./pages/user/UserPage'));
const TwoFactorAuthPage = lazy(() => import('./pages/two-factor-auth/TwoFactorAuthPage'));
const ResetPasswordPage = lazy(() => import('./pages/reset-password/ResetPasswordPage'));
const ProductsPage = lazy(() => import('./pages/products/ProductsPage'));
const ProductPage = lazy(() => import('./pages/product/ProductPage'));
const PostalCodesPage = lazy(() => import('./pages/postal-codes/PostalCodesPage'));
const BranchesPage = lazy(() => import('./pages/branches/BranchesPage'));
const ZonesPage = lazy(() => import('./pages/zones/ZonesPage'));
const AAPsPage = lazy(() => import('./pages/aaps/AAPsPage'));
const GlobalRateCardPage = lazy(() => import('./pages/global-rate-card/GlobalRateCardPage'));
const LodgementsPage = lazy(() => import('./pages/lodgements/LodgementsPage'));
const LodgementPage = lazy(() => import('./pages/lodgement/LodgementPage'));
const ConfigurationPage = lazy(() => import('./pages/configuration/ConfigurationPage'));
const RateCardPage = lazy(() => import('./pages/organization-rate-card/OrganizationRateCardPage'));
const StandardMailRatesPage = lazy(() => import('./pages/standard-mail-rates/StandardMailRatesPage'));
const StandardMailCustomSizesPage = lazy(() => import('./pages/standard-mail-custom-sizes/StandardMailCustomSizesPage'));
const NotificationsPage = lazy(() => import('./pages/notifications/NotificationsPage'));
const HandSortSheetPage = lazy(() => import('./pages/hand-sort-sheet/HandSortSheetPage'));
const ReportingPage = lazy(() => import('./pages/reporting/ReportingPage'))

const OperationsPage = lazy(() => import('./pages/operations/OperationsPage'));
const LodgementLookupPage = lazy(() => import('./pages/lodgement-lookup/LodgementLookupPage'));

const AppRouter: FunctionComponent = () => {

    const routes = createBrowserRouter([
        {
            element: (
                <LazyLoader title='Login'>
                    <LoginPage />
                </LazyLoader>
            ),
            index: true
        },
        {
            path: 'dashboard',
            element: (
                <LazyLoader noTitle isAllowed={(user) => user.role !== 'Operations' && user.role !== 'Supervisor'}>
                    <DashboardPage />
                </LazyLoader>
            ),
            children: [
                {
                    path: 'lodgements',
                    element: (
                        <LazyLoader title='Lodgements' protected>
                            <LodgementsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'lodgements/:lodgementId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['Owner', 'Admin', 'User']}>
                            <LodgementPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations',
                    element: (
                        <LazyLoader title='Organizations' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <OrganizationsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'my-organization',
                    element: (
                        <LazyLoader noTitle allowedRoles={['Owner', 'Admin']}>
                            <OrganizationPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <OrganizationPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/lodgements/:lodgementId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Billing']}>
                            <LodgementPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/lodgements/:lodgementId/hand-sort-sheet',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Billing']}>
                            <HandSortSheetPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'users',
                    element: (
                        <LazyLoader title='Users' allowedRoles={['Owner', 'Admin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/users',
                    element: (
                        <LazyLoader title='Users' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'system-users',
                    element: (
                        <LazyLoader title='System Users' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'users/:userId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['Owner', 'Admin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/users/:userId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'system-users/:userId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'profile',
                    element: (
                        <LazyLoader noTitle protected>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'system-configuration',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <ConfigurationPage />
                        </LazyLoader>
                    ),
                    children: [
                        {
                            path: 'products',
                            element: (
                                <LazyLoader title='Products'>
                                    <ProductsPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'postal-codes',
                            element: (
                                <LazyLoader title='Postal Codes'>
                                    <PostalCodesPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'branches',
                            element: (
                                <LazyLoader title='Branches'>
                                    <BranchesPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'zones',
                            element: (
                                <LazyLoader title='Zones'>
                                    <ZonesPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'aaps',
                            element: (
                                <LazyLoader title='AAPs'>
                                    <AAPsPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'bulk-mail-rate-card',
                            element: (
                                <LazyLoader title='Bulk Mail Rate Card'>
                                    <GlobalRateCardPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'standard-mail-rate-card',
                            element: (
                                <LazyLoader title='Standard Mail Rate Card'>
                                    <StandardMailRatesPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'standard-mail-custom-sizes',
                            element: (
                                <LazyLoader title='Standard Mail Custom Sizes'>
                                    <StandardMailCustomSizesPage />
                                </LazyLoader>
                            )
                        },
                        {
                            path: 'notifications',
                            element: (
                                <LazyLoader title='Notifications'>
                                    <NotificationsPage />
                                </LazyLoader>
                            )
                        }
                    ]
                },
                {
                    path: 'system-configuration/products/:productId',
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <ProductPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'rate-card',
                    element: (
                        <LazyLoader title='Rate Card' allowedRoles={['Owner', 'Admin', 'User']}>
                            <RateCardPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/rate-card',
                    element: (
                        <LazyLoader title='Rate Card' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <RateCardPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'reporting',
                    element: (
                        <LazyLoader title='Reporting' isAllowed={(u) => u.isSystemsUser}>
                            <ReportingPage />
                        </LazyLoader>
                    )
                }
            ]
        },
        {
            path: 'operations',
            element: (
                <LazyLoader noTitle allowedRoles={['Operations', 'Supervisor']}>
                    <OperationsPage />
                </LazyLoader>
            ),
            children: [
                {
                    path: 'lodgement-lookup',
                    element: (
                        <LazyLoader title='Lodgement Lookup' protected>
                            <LodgementLookupPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'manual-lodgement',
                    element: (
                        <LazyLoader title='Manual Lodgement' protected>
                            <HandSortSheetPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'profile',
                    element: (
                        <LazyLoader noTitle protected>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/lodgements/:lodgementId',
                    element: (
                        <LazyLoader noTitle protected>
                            <LodgementPage />
                        </LazyLoader>
                    )
                },
                {
                    path: 'organizations/:organizationId/lodgements/:lodgementId/hand-sort-sheet',
                    element: (
                        <LazyLoader noTitle protected>
                            <HandSortSheetPage />
                        </LazyLoader>
                    )
                }
            ]
        },
        {
            path: 'user-setup',
            element: (
                <LazyLoader title='Setup Account'>
                    <UserSetupPage />
                </LazyLoader>
            )
        },
        {
            path: 'sign-out/:userId',
            element: (
                <LazyLoader title='Sign Out'>
                    <SignOutPage />
                </LazyLoader>
            )
        },
        {
            path: 'setup-2fa',
            element: (
                <LazyLoader title='Setup 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            )
        },
        {
            path: 'verify-2fa',
            element: (
                <LazyLoader title='Verify 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            )
        },
        {
            path: 'reset-password',
            element: (
                <LazyLoader title='Reset Password'>
                    <ResetPasswordPage />
                </LazyLoader>
            )
        },
        {
            path: '*',
            element: (
                <Navigate to='/' replace />
            )
        }
    ]);

    return (
        <RouterProvider router={routes} />
    );
};

export default AppRouter;