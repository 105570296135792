import React, { FunctionComponent } from 'react';

import { Chip, Tooltip } from '@mui/material';
import { Edit, Sort, Sync, Upload } from '@mui/icons-material';

import { TLodgementStatus, TStatus } from '@dxlm/interfaces';
import { splitText } from '@dxlm/formatters';
import { TCustomColors } from 'App.theme';

type TChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | TCustomColors;
type TChipStatus = TStatus | TLodgementStatus | 'Reconciled' | 'Manual';
interface IStatusChipProps {
    status: TChipStatus;
    size?: 'small' | 'medium';
    iconOnly?: boolean;
    textOverride?: string;
}
const StatusChip: FunctionComponent<IStatusChipProps> = (props: IStatusChipProps) => {
    const {
        status,
        size,
        iconOnly,
        textOverride
    } = props;

    const chipText = textOverride ?? (status
        ? splitText(status)
        : '');

    let chipColor: TChipColor = 'default';
    let chipVariant: 'outlined' | 'filled' = 'filled';
    let chipIcon: JSX.Element = null;
    switch (status) {
        case 'SetupRequired': chipColor = 'warning'; break;
        case 'Disabled': chipColor = 'error'; break;
        case 'Active': chipColor = 'success'; break;
        case 'Draft': chipColor = 'default'; break;
        case 'Submitted':
            chipColor = 'primary';
            chipVariant = 'outlined';
            break;
        case 'Processing': chipColor = 'primary'; break;
        case 'OnHold': chipColor = 'warning'; break;
        case 'Complete':
            chipColor = 'success';
            chipVariant = 'outlined';
            break;
        case 'Reconciled':
            chipColor = 'default';
            chipIcon = <Sync fontSize='small' />;
            break;
        case 'Manual':
            chipColor = 'default'
            chipIcon = <Edit fontSize='small' />
            break;
        case 'Uploading':
            chipColor = 'default';
            chipIcon = <Upload fontSize='small' />;
            break;
        case 'Error': chipColor = 'error'; break;
        case 'Billed': chipColor = 'success'; break;
        case 'ApprovalNeeded': chipColor = 'warning'; break;
    }

    if (!chipColor) {
        return null;
    }

    const chip = (
        <Chip
            size={size ?? 'small'}
            variant={chipVariant}
            label={iconOnly ? null : chipText}
            color={chipColor}
            icon={chipIcon}
            className={iconOnly ? 'iconOnly' : null} />
    );

    if (iconOnly) {
        return (
            <Tooltip title={chipText}>
                {chip}
            </Tooltip>
        )
    }

    return chip;
};

export default StatusChip;