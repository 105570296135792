import { FunctionComponent } from 'react';

import { TUserRole, IUser } from '@dxlm/interfaces';
import { useAuth } from '@dxlm/hooks';

interface IRoleVisibilityProps {
    visibleRoles?: TUserRole[];
    notVisibleRoles?: TUserRole[];
    visible?: (user: IUser) => boolean;
    notVisible?: (user: IUser) => boolean;
    children: any;
}
const RoleVisibility: FunctionComponent<IRoleVisibilityProps> = (props: IRoleVisibilityProps) => {

    const {
        visibleRoles,
        notVisibleRoles,
        visible,
        notVisible,
        children
    } = props;
    const { user, userHasRole } = useAuth();

    if (visibleRoles?.length > 0 && !userHasRole(...visibleRoles)) {
        return null;
    } else if (notVisibleRoles?.length > 0 && userHasRole(...notVisibleRoles)) {
        return null;
    } else if (visible && typeof visible === 'function' && !visible(user)) {
        return null;
    } else if (notVisible && typeof notVisible === 'function' && notVisible(user)) {
        return null;
    }

    return children;
};

export default RoleVisibility;