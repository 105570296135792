const PRECISE_NUMBER_FRACTIONS = 6;

const nzdCurrencyFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD'
});
const nzdCurrencyFormatterPrecise = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: PRECISE_NUMBER_FRACTIONS
});

const percentageFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'percent',
    maximumFractionDigits: PRECISE_NUMBER_FRACTIONS
});

const numberFormatter = new Intl.NumberFormat('en-NZ');

const formatCurrency = (amount: number, roundingType: 'STANDARD' | 'PRECISE' = 'STANDARD'): string => {
    if (roundingType === 'PRECISE') {
        return nzdCurrencyFormatterPrecise.format(amount);
    }
    return nzdCurrencyFormatter.format(amount);
}

const formatNumber = (amount: number): string => {
    return numberFormatter.format(amount);
}

const formatPercentage = (amount: number): string => {
    if (!amount && amount !== 0) {
        return '-'
    }
    
    return percentageFormatter.format(amount / 100);
}

const roundNumber = (amount: number, decimals: number): number => {
    const multiplier = Math.pow(10, decimals);
    return Math.ceil(amount * multiplier) / multiplier;
}

const nameOf = <T, K extends keyof T = keyof T>(name: K): K => name;

const parseFormInputName = (inputName: string): { itemName: string, itemIndex: number } => {
    const matches = new RegExp(/([a-zA-Z0-9-]{1,})(\[([0-9]{1,})\]){0,1}/, 'g').exec(inputName);
    const regexMatches = { ItemName: 1, ItemIndex: 3 };

    if (!matches || matches.length < 4) {
        return {
            itemIndex: null,
            itemName: null
        };
    }

    const itemIndex = parseInt(matches[regexMatches.ItemIndex], 10);

    return {
        itemName: matches[regexMatches.ItemName] ?? null,
        itemIndex: isNaN(itemIndex) ? null : itemIndex
    };
};

const isFormNameAnArray = (inputFullKey: string): boolean => {
    return new RegExp(/\[[0-9{1,}]\]/, 'g').test(inputFullKey);
};

const generateGuid = (): string => {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

/**
 * Splits concatenated strings by uppercase letters e.g. `SomeText` -> `Some Text`
 * @param text Text to split
 * @returns Split up text
 */
const splitText = (text: string) => (text ?? '').match(/[A-Z][a-z]+/g)?.join(' ');

export {
    formatCurrency,
    formatNumber,
    formatPercentage,
    nameOf,
    parseFormInputName,
    isFormNameAnArray,
    generateGuid,
    splitText,
    PRECISE_NUMBER_FRACTIONS,
    roundNumber
}