import { FunctionComponent, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Snackbar, Stack } from '@mui/material';

import AppTheme from './App.theme';
import AppRouter from './App.router';

import { AppSettingsContextProvider, HttpClientContextProvider, AuthContextProvider, SnackbarContextProvider } from '@dxlm/contexts';
import { Button } from '@dxlm/components';
import { useAppSettings, useHttpClient } from '@dxlm/hooks';
import { QUERY_CLIENT_STALE_TIME } from '@dxlm/constants';

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: QUERY_CLIENT_STALE_TIME
    }
  }
});

function App() {
  return (
    <AppTheme>
      <SnackbarContextProvider>
        <QueryClientProvider client={QUERY_CLIENT}>
          <AppSettingsContextProvider>
            <HttpClientContextProvider>
              <AuthContextProvider>

                <AppRouter />
                <AppVersionChecker />
                    
              </AuthContextProvider>
            </HttpClientContextProvider>
          </AppSettingsContextProvider>
        </QueryClientProvider>
      </SnackbarContextProvider>
    </AppTheme>
  );
}

const AppVersionChecker: FunctionComponent = () => {

  const { appSettings } = useAppSettings();
  const { buildUrl } = useHttpClient();

  const [updateAvailableSnackbarOpen, setUpdateAvailableSnackbarOpen] = useState(false);
  const [activeVersion, setActiveVersion] = useState('');
  const [versionToUpdateTo, setVersionToUpdateTo] = useState('');
  const [versionToSkip, setVersionToSkip] = useState('');

  const skipVersionUpdate = () => {
    setVersionToSkip(versionToUpdateTo);
    setUpdateAvailableSnackbarOpen(false);
  };

  const addNoCacheHeaders = () => {
    const noCacheEl = document.createElement('meta');
    noCacheEl.httpEquiv = 'Cache-control';
    noCacheEl.content = 'no-cache';
    noCacheEl.id = 'echr-no-cache';

    const cacheExpireEl = document.createElement('meta');
    cacheExpireEl.httpEquiv = 'Expires';
    cacheExpireEl.content = '-1';
    cacheExpireEl.id = 'echr-cache-expire';

    if (!document.getElementById('echr-no-cache')) {
      document.head.appendChild(noCacheEl);
    }

    if (!document.getElementById('echr-cache-expire')) {
      document.head.appendChild(cacheExpireEl);
    }
  }

  const hardReloadPage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const newQueryParams: {[key: string]: string} = {};
    searchParams.forEach((val: string, key: string) => {
      newQueryParams[key] = val;
    });

    newQueryParams['echr'] = 'true';
    addNoCacheHeaders();

    const newUrl = buildUrl(`${window.location.origin}${window.location.pathname}`, newQueryParams);
    window.history.replaceState(null, null, newUrl);
    window.location.reload();
  };

  useEffect(() => {
    if (!appSettings) {
      return;
    }

    if (!activeVersion) {
      setActiveVersion(appSettings.buildVersion);
      window.newrelic?.setCustomAttribute('application.version', appSettings.buildVersion);
      window.newrelic?.setCustomAttribute('environment', appSettings.environment);
      console.log(`Running version: [${appSettings.buildVersion}] in [${appSettings.environment}] mode.`);

      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('echr')) {
        console.log('Adding no cache headers to page...');
        addNoCacheHeaders();
      }
    } else if (!updateAvailableSnackbarOpen) {
      if (versionToSkip !== appSettings.buildVersion && activeVersion !== appSettings.buildVersion) {
        console.debug(`New website version available: v${appSettings.buildVersion}...`);
        setVersionToUpdateTo(appSettings.buildVersion);
        setUpdateAvailableSnackbarOpen(true);
      }
    }
  }, [appSettings]);

  return (
    <Snackbar
      message='A new version is available. Click to update'
      open={updateAvailableSnackbarOpen}
      action={(
        <Stack columnGap={1} display='flex' flexDirection='row'>
          <Button
            variant='text'
            size='small'
            color='error'
            onClick={skipVersionUpdate}
          >
            Close
          </Button>
          <Button
            variant='text'
            size='small'
            color='light'
            onClick={hardReloadPage}
          >
            Update
          </Button>
        </Stack>
      )}
    />
  );
}

export default App;
