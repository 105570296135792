import React, { useCallback, useRef } from 'react';

interface IFileUploader {
    getFile: (accept: string) => Promise<File>,
    fileInput: JSX.Element;
}
export const useFileUploader = (): IFileUploader => {
    const inputRef = useRef<HTMLInputElement>();

    const getFile = useCallback((accept: string): Promise<File> => {
        return new Promise((resolve) => {
            inputRef.current.accept = accept;
            inputRef.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;
                resolve(target.files.length > 0 ? target.files[0] : null);
                inputRef.current.value = null;
            };
            inputRef.current.click();
            console.log('Clicked!');
        });
    }, [inputRef]);

    const fileInput = (
        <input type='file' ref={inputRef} hidden />
    );

    return {
        fileInput,
        getFile
    };
}