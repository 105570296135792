import React, { FunctionComponent, useReducer, useEffect, useCallback } from 'react';

import { IOrganization, IReducerAction, IUser, TUserRole } from '@dxlm/interfaces';
import { useGetCurrentUser } from '@dxlm/hooks/api/UserApi';
import { useGetCurrentOrganization } from '@dxlm/hooks/api/OrganizationApi';

type TAuthContextActions = 'SET_USER' | 'SET_ORGANIZATION' | 'SET_INITIALIZED';

interface IAuthContext {
    isAuthenticated: boolean;
    isInitialized: boolean;
    userHasRole: (...roles: TUserRole[]) => boolean;
    user?: IUser;
    organization?: IOrganization;
}
const initialAuthState: IAuthContext = {
    userHasRole: () => false,
    isAuthenticated: false,
    isInitialized: false
};
export const AuthContext = React.createContext(initialAuthState);

const AuthContextReducer = (state: IAuthContext, action: IReducerAction<TAuthContextActions>): IAuthContext => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, isAuthenticated: !!action.payload && !!state.organization, user: action.payload };
        case 'SET_ORGANIZATION':
            return { ...state, isAuthenticated: !!action.payload && !!state.user, organization: action.payload };
        case 'SET_INITIALIZED':
            return { ...state, isInitialized: action.payload };
        default:
            return state;
    }
}

interface IAuthContextProviderProps {
    children: any;
}
export const AuthContextProvider: FunctionComponent<IAuthContextProviderProps> = (props: IAuthContextProviderProps) => {
    const [state, dispatch] = useReducer(AuthContextReducer, {...initialAuthState});

    const { isFetched: userSelfFetched } = useGetCurrentUser({
      autoLogOutOnAuthError: false,
      autoShowErrorMessages: false,
      onSuccess: (user) => {
        dispatch({ type: 'SET_USER', payload: user });
      },
      onError: () => {
        dispatch({ type: 'SET_USER', payload: null });
      }
    });
    const { isFetched: organizationSelfFetched } = useGetCurrentOrganization({
      autoLogOutOnAuthError: false,
      autoShowErrorMessages: false,
      onSuccess: (organization) => {
        dispatch({ type: 'SET_ORGANIZATION', payload: organization });
      },
      onError: () => {
        dispatch({ type: 'SET_ORGANIZATION', payload: null });
      }
    });

    const userHasRole = useCallback((...roles: TUserRole[]) => {
        return roles.includes(state.user.role) ?? false;
    }, [state.user]);

    useEffect(() => {
        window.newrelic?.setCustomAttribute('userId', state.user?.id);
        window.newrelic?.setCustomAttribute('organizationId', state.organization?.id);
    }, [state.user, state.organization]);

    useEffect(() => {        
        dispatch({
            type: 'SET_INITIALIZED',
            payload: userSelfFetched && organizationSelfFetched
        });
    }, [userSelfFetched, organizationSelfFetched]);

    return (
        <AuthContext.Provider value={{...state, userHasRole}}>
            {props.children}
        </AuthContext.Provider>
    )
};