import React, { FunctionComponent, useRef, useEffect } from 'react';

import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { IRateCardAAP, IRateCardProduct } from '@dxlm/interfaces';
import { TRateCardType } from '.';

interface IRateCardProductHeaderProps {
    product: IRateCardProduct;
    aaps: IRateCardAAP[];
    columnWidth: number;
    onScroll: (scrollLeft: number) => void;
    scrollHeaderLoaded: (scrollHeader: HTMLDivElement) => void;
    mode: TRateCardType;
}
const RateCardProductHeader: FunctionComponent<IRateCardProductHeaderProps> = (props: IRateCardProductHeaderProps) => {

    const scrollingGridRef = useRef<HTMLDivElement>();

    const handleGridScroll = (e: React.UIEvent) => {
        props.onScroll(scrollingGridRef.current.scrollLeft);
    };

    const aapHeadings: string[] = [
        'AAP %:',
        ...props.aaps.map(a => {
            if (a.lowerLimit === 0) {
                return `${a.upperLimit}% or below`;
            } else if (a.upperLimit === 100) {
                return `${a.lowerLimit}%+`;
            }
            return `${a.lowerLimit}-${a.upperLimit}%`;
        })
    ];

    useEffect(() => {
        if (!scrollingGridRef.current) {
            return;
        }

        props.scrollHeaderLoaded(scrollingGridRef.current);
    }, [scrollingGridRef.current]);

    return (
        <>
            <Grid xs={12} md={4} className='dense-grid'>
                <Stack direction='column'>
                    <Typography variant='h6'>{props.product.name}</Typography>
                    <Typography variant='caption' className='no-overflow'>({props.product.description})</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} md={8} className='rate-card-shade-back dense-grid' display='flex' alignItems='flex-end'>
                <div
                    className='rate-card-grid-scroller'
                    ref={scrollingGridRef}
                    onScroll={handleGridScroll}
                >
                    <Stack direction='column' rowGap={1} flex='1'>
                        <Typography variant='caption'>
                            {
                                props.mode === 'customer-discount'
                                    ? 'Rate Discount (%)'
                                    : 'Price (NZD) excl. GST'
                            }
                        </Typography>
                        <Stack direction='row'>
                            {
                                aapHeadings.map(x => (
                                    <Typography
                                        key={x}
                                        variant='body2'
                                        className='rate-card-product-column'
                                        sx={{
                                            fontWeight: 'bold',
                                            minWidth: `${props.columnWidth}px`
                                        }}
                                    >
                                        {x}
                                    </Typography>
                                ))
                            }
                        </Stack>
                    </Stack>
                </div>
            </Grid>
        </>
    );
};

export default RateCardProductHeader;