import React, { FunctionComponent } from 'react';

import { CircularProgress, Box, Typography } from '@mui/material';

interface IProgressSpinnerProps {
    variant?: 'determinate' | 'indeterminate';
    value?: number;
    showProgressLabel?: boolean;
    size?: number;
}
const ProgressSpinner: FunctionComponent<IProgressSpinnerProps> = (props: IProgressSpinnerProps) => {

    const spinner = (
        <CircularProgress size={props.showProgressLabel && !props.size ? 60 : props.size} variant={props.variant} value={props.value} />
    );

    if (props.variant === 'determinate' && props.showProgressLabel) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                {spinner}

                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant='caption'
                        component='div'
                        sx={{
                            color: 'var(--color-primary)'
                        }}
                    >
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return spinner;
};

export default ProgressSpinner;