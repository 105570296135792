export enum QueryKeys {
  AppSettings = 'AppSettingsQueryKey',
  GlobalRateCard = 'GlobalRateCardQueryKey',
  GlobalRateCardEffectiveDates = 'GlobalRateCardEffectiveDatesQueryKey',
  OrganizationDiscountRateCard = 'OrganizationDiscountRateCardQueryKey',
  OrganizationRateCard = 'OrganizationRateCardQueryKey',
  Lodgements = 'LodgementsQueryKey',
  Users = 'UsersQueryKey',
  Branches = 'BranchesQueryKey',
  Organizations = 'OrganizationsQueryKey',
  LodgementTimelineActivities = 'LodgementTimelineActivitiesQueryKey',
}