import React, { FunctionComponent, useRef, useState, useEffect } from 'react';

import { TableRow, TableCell, Box } from '@mui/material';

import { Checkbox } from '@dxlm/components';

import { ITableColumn } from './table-header-column';
import { Link } from 'react-router-dom';
import { DragIndicator } from '@mui/icons-material';

export interface IOrderChangeEvent {
    oldIndex: number;
    newIndex: number;
}

interface IOrderRowInfo {
    originalIndex: number;
    newIndex: number;
    startingReact: DOMRect;
    yOffset: number;
    element: HTMLElement;
    minY: number;
    maxY: number;
    isActiveRow?: boolean;
}

interface ITableDataRowProps {
    row: any;
    columns: ITableColumn[];
    rowIndex: number;
    rowLink?: (row: any) => string;
    onSelected?: () => void;
    disabled?: boolean;

    orderable?: boolean;
    tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement>;
    orderChanged?: (events: IOrderChangeEvent[]) => void;

    checkboxSelection?: boolean;
    checkboxChecked?: boolean;
    checkboxSelectionChanged?: () => void;
}
const TableDataRow: FunctionComponent<ITableDataRowProps> = (props: ITableDataRowProps) => {

    const transitionDuration = 150;
    const [isMouseDown, setIsMouseDown] = useState(false);
    const cursorStartingY = useRef<number>();
    const cursorLastKnownY = useRef<number>();
    const rowsInfo = useRef<IOrderRowInfo[]>([]);

    const mouseMoved = (event: any) => {
        if (!isMouseDown) {
            return;
        }

        let newPos: number = null;
        if (event instanceof MouseEvent) {
            newPos = event.pageY;
        } else if (event instanceof TouchEvent) {
            newPos = event.touches[0].pageY;
        }

        const tableBodyRect = props.tableBodyRef.current.getBoundingClientRect();
        const tableBodyMinY = tableBodyRect.top;
        const tableBodyMaxY = tableBodyRect.top + tableBodyRect.height;

        const rowsInfoClone = [...rowsInfo.current];
        
        const activeRow = rowsInfoClone.find(x => x.isActiveRow);
        
        let yOffset: number = newPos - cursorStartingY.current;
        if (activeRow.startingReact.top + yOffset < tableBodyMinY) {
            yOffset = tableBodyMinY - activeRow.startingReact.top;
        } else if (activeRow.startingReact.top + activeRow.startingReact.height + yOffset > tableBodyMaxY) {
            yOffset = tableBodyMaxY - (activeRow.startingReact.top + activeRow.startingReact.height)
        }

        activeRow.yOffset = yOffset;
        activeRow.minY = activeRow.startingReact.top + activeRow.yOffset;
        activeRow.maxY = activeRow.startingReact.top + activeRow.startingReact.height + activeRow.yOffset;

        const movingDirection: 'UP' | 'DOWN' | 'UNKNOWN' = Math.abs(newPos - cursorLastKnownY.current) < 1 ? 'UNKNOWN' : newPos > cursorLastKnownY.current ? 'DOWN' : 'UP';
        const overlapBuffer = activeRow.startingReact.height * 0.3;

        const overlappingRow = rowsInfoClone.find(row => !row.isActiveRow && (
            (activeRow.maxY + overlapBuffer < row.maxY && activeRow.maxY - overlapBuffer > row.minY && movingDirection === 'DOWN') ||
            (activeRow.minY - overlapBuffer > row.minY && activeRow.minY + overlapBuffer < row.maxY && movingDirection === 'UP')
        ));
        if (overlappingRow) {
            const newActiveRowIndex = overlappingRow.newIndex;
            overlappingRow.newIndex = activeRow.newIndex;
            activeRow.newIndex = newActiveRowIndex;
 
            if (overlappingRow.newIndex > activeRow.newIndex) { // Move overlapping 1 down
                overlappingRow.yOffset += activeRow.startingReact.height;
            } else { // Move overlapping 1 up
                overlappingRow.yOffset -= activeRow.startingReact.height;
            }

            overlappingRow.minY = overlappingRow.startingReact.top + overlappingRow.yOffset;
            overlappingRow.maxY = overlappingRow.startingReact.top + overlappingRow.startingReact.height + overlappingRow.yOffset;
        }

        rowsInfoClone.forEach(row => {
            row.element.style.transform = `translateY(${row.yOffset}px)`
        });

        if (movingDirection !== 'UNKNOWN') {
            cursorLastKnownY.current = newPos;
        }

        rowsInfo.current = rowsInfoClone;
    };

    const startReOrderListeners = (yPos: number) => {
        setIsMouseDown(true);
        cursorStartingY.current = yPos;
        cursorLastKnownY.current = yPos;

        rowsInfo.current = [];
        props.tableBodyRef.current.querySelectorAll('tr').forEach((tableRow, index) => {
            const rect = tableRow.getBoundingClientRect();
            const isActiveRow = index === props.rowIndex;

            rowsInfo.current.push({
                newIndex: index,
                originalIndex: index,
                yOffset: 0,
                startingReact: rect,
                isActiveRow: isActiveRow,
                element: tableRow,
                minY: rect.top,
                maxY: rect.top + rect.height
            });

            if (!isActiveRow) {
                tableRow.style.transition = `transform ${transitionDuration}ms linear`;
            } else {
                tableRow.classList.add('table-row-order-active');
            }
        });
    };

    const finishReOrder = () => {
        if (!isMouseDown) {
            return;
        }

        setIsMouseDown(false);

        const activeRow = rowsInfo.current.find(x => x.isActiveRow);
        const orderedRows = [...rowsInfo.current].sort((a, b) => a.newIndex - b.newIndex)
        const rowsAboveActive = orderedRows.filter(x => x.newIndex < activeRow.newIndex);

        let newActiveRowTop = props.tableBodyRef.current.getBoundingClientRect().top;
        if (rowsAboveActive.length > 0) {
            newActiveRowTop += rowsAboveActive.map((row) => row.startingReact.height).reduce((a, b) => a + b);
        }
        const newOffset = newActiveRowTop - activeRow.startingReact.top;
        activeRow.element.style.transform = `translateY(${newOffset}px)`;

        setTimeout(() => {
            rowsInfo.current.forEach((row) => {
                row.element.style.transition = '';
                row.element.style.transform = '';
                if (row.isActiveRow) {
                    row.element.classList.remove('table-row-order-active');
                }
            });

            if (props.orderChanged && typeof props.orderChanged === 'function') {
                const changedRows: IOrderChangeEvent[] = rowsInfo.current.filter(x => x.newIndex !== x.originalIndex).map(x => ({ newIndex: x.newIndex, oldIndex: x.originalIndex }));
                props.orderChanged(changedRows);
            }
        }, transitionDuration);

    };

    const getCellContent = (column: ITableColumn) => {
        if (column.element && typeof column.element === 'function') {
            return column.element(props.row);
        } else if (column.dataKey.indexOf('.') >= 0) {
            const keyParts = column.dataKey.split('.');
            let cellContent = Object.assign({}, props.row);
            for (const key of keyParts) {
                cellContent = cellContent[key];
            }
            return cellContent;
        }

        return props.row[column.dataKey];
    }

    useEffect(() => {
        if (isMouseDown) {
            document.body.addEventListener('mousemove', mouseMoved);
            document.body.addEventListener('touchmove', mouseMoved);
            document.body.addEventListener('mouseup', finishReOrder);
            document.body.addEventListener('mouseleave', finishReOrder);
            document.body.addEventListener('touchend', finishReOrder);
        }

        return () => {
            try {
                document.body.removeEventListener('mousemove', mouseMoved);
                document.body.removeEventListener('touchmove', mouseMoved);
                document.body.removeEventListener('mouseup', finishReOrder);
                document.body.removeEventListener('mouseleave', finishReOrder);
                document.body.removeEventListener('touchend', finishReOrder);
            } catch {}
        }
    }, [isMouseDown]);

    return (
        <TableRow hover={true} className='pointer'>
            {
                props.orderable && (
                    <TableCell>
                        <Box
                            className='table-row-order-wrapper'
                            onTouchStart={(e) => startReOrderListeners(e.touches[0].pageY)}
                            onMouseDown={(e) => startReOrderListeners(e.pageY)}
                            onMouseUp={finishReOrder}
                            onTouchEnd={finishReOrder}
                        >
                            <DragIndicator />
                        </Box>
                    </TableCell>
                )
            }
            {
                props.checkboxSelection && (
                    <TableCell>
                        <Checkbox
                            value={props.checkboxChecked}
                            onChange={props.checkboxSelectionChanged}
                            disabled={props.disabled}
                        />
                    </TableCell>
                )
            }
            {
                props.columns.map(column => (
                    <TableCell
                        key={column.dataKey ?? column.title}
                        onClick={column.disableRowClick || props.disabled ? null : props.onSelected}
                        sx={{position: 'relative', whiteSpace: column.allowWrapping ? 'normal' : 'nowrap'}}
                    >
                        {getCellContent(column)}
                        {
                            !column.disableRowClick && props.rowLink && typeof props.rowLink === 'function' && (
                                <Link to={props.rowLink(props.row)} className='absolute-fill' />
                            )
                        }
                    </TableCell>
                ))
            }
        </TableRow>
    )
}

export default TableDataRow;