import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Unstable_Grid2 as Grid, Typography, Stack, Skeleton, Alert } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Button } from '@dxlm/components';
import { useScreenSize } from '@dxlm/hooks';

export const PageFrameSkeletonLoader: FunctionComponent = () => {
    return (
        <Stack spacing={3}>
            <Skeleton style={{height: '30px', width: '70%'}} />
            <Skeleton style={{height: '30px', width: '40%'}} />
            <Skeleton style={{height: '30px', width: '90%'}} />
        </Stack>
    )
};

interface IPageFrameProps {
    title: React.ReactNode;
    enableBackNavigation?: boolean;
    className: string;
    titleActions?: JSX.Element;
    initialized?: boolean;
    children: any;
    isPageLoadError?: boolean;
    pageErrorMessage?: string;
}
const PageFrame: FunctionComponent<IPageFrameProps> = (props: IPageFrameProps) => {
    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    const pageTitleText = (
        <Typography
            variant={isMobile ? 'h5' : 'h4'}
            flex='1'
        >
            {props.title}
        </Typography>
    );

    const pageTitle = props.enableBackNavigation ? (
        <Stack direction='row' spacing={3} alignItems='center' width={isMobile ? '100%' : 'auto'}>
            <Button iconOnly icon={<ArrowBack />} onClick={() => navigate(-1)} />
            {pageTitleText}
        </Stack>
    ) : pageTitleText;

    return (
        <div className={props.className}>
            <Grid container spacing={5}>
                <Grid
                    xs={12}
                    justifyContent='space-between'
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    display='flex'
                    rowGap={2}    
                    columnGap={2}    
                    flexWrap='wrap'
                >
                    {
                        props.initialized === false ? (
                            <Skeleton style={{height: '50px', width: '20%'}} />
                        ) : (
                            <>
                                {pageTitle}
                                {props.titleActions}
                            </>
                        )
                    }
                </Grid>

                <Grid xs={12} flexDirection='column'>
                    {
                        props.initialized === false ? (
                            <PageFrameSkeletonLoader />
                        ) : props.isPageLoadError && props.pageErrorMessage ? (
                            <Alert severity='error'>
                                {props.pageErrorMessage}
                            </Alert>
                        ) : props.isPageLoadError ? (
                            <Alert severity='error'>
                                Something went wrong. Please <span className='link' onClick={() => window.location.reload()}>refresh the page</span> or try again later.
                            </Alert>
                        ) : props.children
                    }
                </Grid>
            </Grid>
        </div>
    )
};

export default PageFrame;