import React, { FunctionComponent, useState, useMemo } from 'react';
import './site-header.scss';

import { Link } from 'react-router-dom';

import { Person, Menu as MenuIcon } from '@mui/icons-material';

import { Menu, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';

import { Button } from '@dxlm/components';
import { IMenuItem } from '@dxlm/interfaces';
import { useScreenSize, useAuth } from '@dxlm/hooks';

import dxMailLMLogo from '@dxlm/images/dx-mail-lm-logo.png';

interface ISiteHeaderProps {
    menuItems: IMenuItem[];
    homePageLink: string;
}
const SiteHeader: FunctionComponent<ISiteHeaderProps> = (props: ISiteHeaderProps) => {
    const { user } = useAuth();
    const { isMobile } = useScreenSize();
    const userFullName = useMemo(() => `${user.name} ${user.surname}`, [user]);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
    const userMenuOpen = Boolean(userMenuAnchorEl);

    return (
        <div className='site-header'>
            <Link to={props.homePageLink}>
                <img src={dxMailLMLogo} className='dxmlm-logo' alt='DX Mail Lodgement Manager' />
            </Link>
            <div className='user-actions'>
                <Button
                    variant='text'
                    icon={isMobile ? <MenuIcon /> : <Person />}
                    iconOnly={isMobile}
                    color='primary'
                    size='small'
                    aria-controls={userMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={userMenuOpen ? 'true' : undefined}
                    onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}
                >
                    {isMobile ? '' : userFullName}
                </Button>

                <Menu
                    anchorEl={userMenuAnchorEl}
                    open={userMenuOpen}
                    onClose={() => setUserMenuAnchorEl(null)}
                >
                    {
                        props.menuItems.filter(x => !x.hidden).map((x, index) => {
                            if (x.isDivider) {
                                return <Divider key={index} />
                            }

                            return (
                                <Link to={x.value} key={index} onClick={() => setUserMenuAnchorEl(null)}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            {x.icon}
                                        </ListItemIcon>
                                        <ListItemText disableTypography>
                                            <Typography variant='body2'>{x.text}</Typography>
                                        </ListItemText>
                                    </MenuItem>
                                </Link>
                            )
                        })
                    }
                </Menu>
            </div>
        </div>
    )
};

export default SiteHeader;